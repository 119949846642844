.component-9 {
  background-image: url(../../../static/img/mg-7905-copy-1-2.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 673px;
  position: relative;
  width: 449px;
}

.component-9:hover {
  background-image: url(../../../static/img/mg-7905-copy-1-1.png);
}

.component-9 .text-wrapper-6 {
  color: #ffffff;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 29px;
  left: 258px;
  letter-spacing: 0;
  line-height: 28.8px;
  position: absolute;
  text-align: center;
  top: 35px;
  white-space: nowrap;
}

.component-9:hover .text-wrapper-6 {
  top: 34px;
}

.component-9 .text-wrapper-7 {
  color: #ffffff;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 17px;
  left: 319px;
  letter-spacing: 0;
  line-height: 16.8px;
  position: absolute;
  text-align: right;
  top: 68px;
  white-space: nowrap;
}

.component-9:hover .text-wrapper-7 {
  top: 67px;
}
