.component-1 {
  height: 1016px;
  width: 1450px;
}

.component-1 .overlap-group-3 {
  height: 1016px;
  position: relative;
}

.component-1 .rectangle-2 {
  background-color: #143b35;
  height: 902px;
  left: 0;
  position: absolute;
  top: 91px;
  width: 1440px;
}

.component-1 .DSC-copy-5 {
  height: 1016px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1450px;
}

.component-1 .DSC-copy-6 {
  height: 756px;
  left: 473px;
  object-fit: cover;
  position: absolute;
  top: 155px;
  width: 494px;
}

.component-1 .IMG {
  height: 429px;
  left: 989px;
  object-fit: cover;
  position: absolute;
  top: 356px;
  width: 285px;
}

.component-1 .IMG-2 {
  height: 429px;
  left: 1299px;
  object-fit: cover;
  position: absolute;
  top: 356px;
  width: 151px;
}

.component-1 .IMG-3 {
  height: 429px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 356px;
  width: 138px;
}

.component-1 .IMG-4 {
  height: 429px;
  left: 163px;
  object-fit: cover;
  position: absolute;
  top: 356px;
  width: 286px;
}

.component-1 .frame-10 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 4px;
  left: 88px;
  position: absolute;
  top: 200px;
}

.component-1 .rasum {
  color: #ffffff;
  font-family: "Charter-Bold", Helvetica;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 56.2px;
  margin-top: -1px;
  position: relative;
}

.component-1 .a-day-in-the-life-of {
  color: #ffffff;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
  position: relative;
  width: 334px;
}

.component-1 .text-wrapper-21 {
  color: #ffffff;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 989px;
  letter-spacing: 0;
  line-height: 22.5px;
  position: absolute;
  top: 821px;
  width: 334px;
}

.component-1 .frame-163004 {
  width: 269px;
}

.component-1 .frame-163005 {
  width: 334px;
}

.component-1 .property-1-frame-163004 {
  font-size: 16px;
  line-height: 22.5px;
}

.component-1 .property-1-frame-163005 {
  font-size: 20px;
  line-height: 28.1px;
}
