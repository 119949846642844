.component-14 {
  height: 930px;
  overflow: hidden;
  position: relative;
  width: 584px;
}

.component-14 .group-5 {
  height: 2761px;
  left: 0;
  position: absolute;
  width: 304px;
}

.component-14 .HN {
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 937px;
  width: 304px;
}

.component-14 .MG-copy {
  object-fit: cover;
  position: absolute;
  width: 304px;
}

.component-14 .MG-copy-2 {
  height: 460px;
  left: -5931px;
  object-fit: cover;
  position: absolute;
  top: 2575px;
  width: 304px;
}

.component-14 .MG-copy-3 {
  object-fit: cover;
  position: absolute;
  width: 304px;
}

.component-14 .element {
  height: 406px;
  left: -5931px;
  object-fit: cover;
  position: absolute;
  top: 3045px;
  width: 304px;
}

.component-14 .element-2 {
  left: 0;
  object-fit: cover;
  position: absolute;
  width: 304px;
}

.component-14 .group-6 {
  height: 2083px;
  left: 314px;
  position: absolute;
  width: 270px;
}

.component-14 .element-3 {
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 832px;
  width: 270px;
}

.component-14 .element-4 {
  left: 0;
  object-fit: cover;
  position: absolute;
  width: 270px;
}

.component-14 .element-5 {
  height: 406px;
  object-fit: cover;
  position: absolute;
  width: 270px;
}

.component-14 .element-6 {
  object-fit: cover;
  position: absolute;
  width: 270px;
}

.component-14 .element-7 {
  height: 419px;
  left: -5931px;
  object-fit: cover;
  position: absolute;
  top: 2354px;
  width: 270px;
}

.component-14 .frame-162968 {
  top: -889px;
}

.component-14 .frame-162969 {
  top: -89px;
}

.component-14 .property-1-3-frame-162968 {
  top: 9px;
}

.component-14 .property-1-3-frame-162969 {
  top: -691px;
}

.component-14 .group-5.frame-162968 .HN {
  height: 468px;
}

.component-14 .group-5.frame-162969 .HN {
  height: 82px;
}

.component-14 .group-5.frame-162968 .MG-copy {
  height: 404px;
  left: 0;
  top: 1415px;
}

.component-14 .group-5.frame-162969 .MG-copy {
  height: 460px;
  left: -5931px;
  top: 2105px;
}

.component-14 .group-5.frame-162968 .MG-copy-3 {
  height: 460px;
  left: -5931px;
  top: 690px;
}

.component-14 .group-5.frame-162969 .MG-copy-3 {
  height: 371px;
  left: 0;
  top: 89px;
}

.component-14 .group-5.frame-162968 .element-2 {
  height: 38px;
  top: 889px;
}

.component-14 .group-5.frame-162969 .element-2 {
  height: 457px;
  top: 470px;
}

.component-14 .group-6.property-1-3-frame-162968 .element-3 {
  height: 89px;
}

.component-14 .group-6.property-1-3-frame-162969 .element-3 {
  height: 406px;
}

.component-14 .group-6.property-1-3-frame-162968 .element-4 {
  height: 406px;
  top: 416px;
}

.component-14 .group-6.property-1-3-frame-162969 .element-4 {
  height: 131px;
  top: 691px;
}

.component-14 .group-6.property-1-3-frame-162968 .element-5 {
  left: 0;
  top: 0;
}

.component-14 .group-6.property-1-3-frame-162969 .element-5 {
  left: -5931px;
  top: 690px;
}

.component-14 .group-6.property-1-3-frame-162968 .element-6 {
  height: 406px;
  left: -5931px;
  top: 1938px;
}

.component-14 .group-6.property-1-3-frame-162969 .element-6 {
  height: 373px;
  left: 0;
  top: 1248px;
}
