.component-13 {
  background-image: url(../../../static/img/6-31640319-2.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 578px;
  position: relative;
  width: 384px;
}

.component-13:hover {
  background-image: url(../../../static/img/6-31640319-1.png);
}

.component-13 .text-wrapper-10 {
  color: #ffffff;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 29px;
  left: 198px;
  letter-spacing: 0;
  line-height: 28.8px;
  position: absolute;
  text-align: center;
  top: 27px;
  white-space: nowrap;
}

.component-13:hover .text-wrapper-10 {
  top: 32px;
}

.component-13 .text-wrapper-11 {
  color: #ffffff;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 17px;
  left: 292px;
  letter-spacing: 0;
  line-height: 16.8px;
  position: absolute;
  text-align: right;
  top: 60px;
  white-space: nowrap;
}

.component-13:hover .text-wrapper-11 {
  top: 65px;
}
