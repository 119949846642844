.component {
  background-image: url(../../../static/img/rectangle-373-1.png);
  background-size: cover;
  height: 599px;
  width: 646px;
}

.component:hover {
  background-image: url(../../../static/img/rectangle-382.png);
  background-position: 50% 50%;
  background-size: 100% 100%;
}
