.component-10 {
  background-image: url(../../../static/img/dsc-5698-1-2.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 529px;
  position: relative;
  width: 795px;
}

.component-10:hover {
  background-image: url(../../../static/img/dsc-5698-1-1.png);
}

.component-10 .text-wrapper-8 {
  color: #ffffff;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 29px;
  left: 447px;
  letter-spacing: 0;
  line-height: 28.8px;
  position: absolute;
  text-align: center;
  top: 32px;
  white-space: nowrap;
}

.component-10:hover .text-wrapper-8 {
  top: 30px;
}

.component-10 .text-wrapper-9 {
  color: #ffffff;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 17px;
  left: 665px;
  letter-spacing: 0;
  line-height: 16.8px;
  position: absolute;
  text-align: right;
  top: 65px;
  white-space: nowrap;
}

.component-10:hover .text-wrapper-9 {
  top: 63px;
}
