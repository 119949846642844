.property-frame-wrapper {
  height: 355px;
  width: 601px;
}

.property-frame-wrapper.frame-162973 {
  background-image: url(../../../static/img/rectangle-383-2.png);
  background-size: 100% 100%;
}

.property-frame-wrapper.frame-162972 {
  background-image: url(../../../static/img/rectangle-383-3.png);
  background-size: 100% 100%;
}

.property-frame-wrapper.frame-162974 {
  background-image: url(../../../static/img/rectangle-383-1.png);
  background-position: 50% 50%;
  background-size: cover;
}
