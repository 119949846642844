.component-26 {
  height: 67px;
  overflow: hidden;
  width: 390px;
}

.component-26 .group-7 {
  height: 480px;
  position: relative;
}

.component-26 .we-conceptualise {
  color: transparent;
  font-size: 48px;
  letter-spacing: 0;
  line-height: 67.4px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.component-26 .span {
  color: #ffffff;
}

.component-26 .text-wrapper-14 {
  color: #ffebdd;
}

.component-26 .we-curate {
  color: transparent;
  font-size: 48px;
  letter-spacing: 0;
  line-height: 67.4px;
  position: absolute;
  text-align: center;
  top: 92px;
  white-space: nowrap;
}

.component-26 .we-collaborate {
  color: transparent;
  font-size: 48px;
  letter-spacing: 0;
  line-height: 67.4px;
  position: absolute;
  text-align: center;
  top: 199px;
  white-space: nowrap;
}

.component-26 .we-create {
  color: transparent;
  font-size: 48px;
  letter-spacing: 0;
  line-height: 67.4px;
  position: absolute;
  text-align: center;
  top: 306px;
  white-space: nowrap;
}

.component-26 .we-are-passionate {
  color: transparent;
  font-size: 48px;
  left: 0;
  letter-spacing: 0;
  line-height: 67.4px;
  position: absolute;
  text-align: center;
  top: 413px;
  white-space: nowrap;
}

.component-26 .frame-162993 {
  top: -304px;
  width: 400px;
}

.component-26 .frame-162991 {
  top: -92px;
  width: 400px;
}

.component-26 .frame-162994 {
  left: -1px;
  top: -413px;
  width: 402px;
}

.component-26 .frame-162992 {
  top: -199px;
  width: 400px;
}

.component-26 .frame-162990 {
  width: 400px;
}

.component-26 .group-7.frame-162993 .we-conceptualise {
  font-family: "Proxima Nova-Regular", Helvetica;
  font-weight: 400;
  left: 4px;
}

.component-26 .group-7.frame-162991 .we-conceptualise {
  font-family: "Proxima Nova-Regular", Helvetica;
  font-weight: 400;
  left: 4px;
}

.component-26 .group-7.frame-162994 .we-conceptualise {
  font-family: "Proxima Nova-Regular", Helvetica;
  font-weight: 400;
  left: 5px;
}

.component-26 .group-7.frame-162992 .we-conceptualise {
  font-family: "Proxima Nova-Regular", Helvetica;
  font-weight: 400;
  left: 4px;
}

.component-26 .group-7.frame-162990 .we-conceptualise {
  font-family: "Proxima Nova-Medium", Helvetica;
  font-weight: 500;
  left: 3px;
}

.component-26 .group-7.frame-162993 .we-curate {
  font-family: "Proxima Nova-Regular", Helvetica;
  font-weight: 400;
  left: 84px;
}

.component-26 .group-7.frame-162991 .we-curate {
  font-family: "Proxima Nova-Medium", Helvetica;
  font-weight: 500;
  left: 84px;
}

.component-26 .group-7.frame-162994 .we-curate {
  font-family: "Proxima Nova-Regular", Helvetica;
  font-weight: 400;
  left: 85px;
}

.component-26 .group-7.frame-162992 .we-curate {
  font-family: "Proxima Nova-Regular", Helvetica;
  font-weight: 400;
  left: 84px;
}

.component-26 .group-7.frame-162990 .we-curate {
  font-family: "Proxima Nova-Regular", Helvetica;
  font-weight: 400;
  left: 84px;
}

.component-26 .group-7.frame-162993 .we-collaborate {
  font-family: "Proxima Nova-Regular", Helvetica;
  font-weight: 400;
  left: 33px;
}

.component-26 .group-7.frame-162991 .we-collaborate {
  font-family: "Proxima Nova-Regular", Helvetica;
  font-weight: 400;
  left: 33px;
}

.component-26 .group-7.frame-162994 .we-collaborate {
  font-family: "Proxima Nova-Regular", Helvetica;
  font-weight: 400;
  left: 34px;
}

.component-26 .group-7.frame-162992 .we-collaborate {
  font-family: "Proxima Nova-Medium", Helvetica;
  font-weight: 500;
  left: 32px;
}

.component-26 .group-7.frame-162990 .we-collaborate {
  font-family: "Proxima Nova-Regular", Helvetica;
  font-weight: 400;
  left: 33px;
}

.component-26 .group-7.frame-162993 .we-create {
  font-family: "Proxima Nova-Medium", Helvetica;
  font-weight: 500;
  left: 84px;
}

.component-26 .group-7.frame-162991 .we-create {
  font-family: "Proxima Nova-Regular", Helvetica;
  font-weight: 400;
  left: 84px;
}

.component-26 .group-7.frame-162994 .we-create {
  font-family: "Proxima Nova-Regular", Helvetica;
  font-weight: 400;
  left: 85px;
}

.component-26 .group-7.frame-162992 .we-create {
  font-family: "Proxima Nova-Regular", Helvetica;
  font-weight: 400;
  left: 84px;
}

.component-26 .group-7.frame-162990 .we-create {
  font-family: "Proxima Nova-Regular", Helvetica;
  font-weight: 400;
  left: 84px;
}

.component-26 .group-7.frame-162993 .we-are-passionate {
  font-family: "Proxima Nova-Regular", Helvetica;
  font-weight: 400;
}

.component-26 .group-7.frame-162991 .we-are-passionate {
  font-family: "Proxima Nova-Regular", Helvetica;
  font-weight: 400;
}

.component-26 .group-7.frame-162994 .we-are-passionate {
  font-family: "Proxima Nova-Medium", Helvetica;
  font-weight: 500;
}

.component-26 .group-7.frame-162992 .we-are-passionate {
  font-family: "Proxima Nova-Regular", Helvetica;
  font-weight: 400;
}

.component-26 .group-7.frame-162990 .we-are-passionate {
  font-family: "Proxima Nova-Regular", Helvetica;
  font-weight: 400;
}
