.component-11 {
  background-image: url(../../../static/img/img-5242-1-1-2.png);
  background-size: 100% 100%;
  height: 434px;
  position: relative;
  width: 450px;
}

.component-11:hover {
  background-image: url(../../../static/img/img-5242-1-1-1.png);
  background-position: 50% 50%;
  background-size: cover;
}

.component-11 .text-wrapper-12 {
  color: #ffffff;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 29px;
  left: 249px;
  letter-spacing: 0;
  line-height: 28.8px;
  position: absolute;
  text-align: center;
  top: 22px;
  white-space: nowrap;
}

.component-11 .text-wrapper-13 {
  color: #ffffff;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 17px;
  left: 280px;
  letter-spacing: 0;
  line-height: 16.8px;
  position: absolute;
  text-align: right;
  top: 55px;
  white-space: nowrap;
}
