.component-12 {
  background-image: url(../../../static/img/6-31640318-2.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 578px;
  position: relative;
  width: 383px;
}

.component-12:hover {
  background-image: url(../../../static/img/6-31640318-1.png);
}

.component-12 .text-wrapper-5 {
  color: #ffffff;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 29px;
  left: 157px;
  letter-spacing: 0;
  line-height: 28.8px;
  position: absolute;
  text-align: center;
  top: 23px;
  white-space: nowrap;
}

.component-12:hover .text-wrapper-5 {
  top: 24px;
}

.component-12 .p {
  color: #ffffff;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 17px;
  left: 187px;
  letter-spacing: 0;
  line-height: 16.8px;
  position: absolute;
  text-align: right;
  top: 56px;
  white-space: nowrap;
}

.component-12:hover .p {
  top: 57px;
}
