:root {
  --core-colorswhite: #fff;
  --primary-light: #dcf2ed;
  --white: #fff;
}

.component {
  background-image: url("rectangle-373-1.87b83d5e.png");
  background-size: cover;
  width: 646px;
  height: 599px;
}

.component:hover {
  background-image: url("rectangle-382.83146998.png");
  background-position: 50%;
  background-size: 100% 100%;
}

.component-1 {
  width: 1450px;
  height: 1016px;
}

.component-1 .overlap-group-3 {
  height: 1016px;
  position: relative;
}

.component-1 .rectangle-2 {
  background-color: #143b35;
  width: 1440px;
  height: 902px;
  position: absolute;
  top: 91px;
  left: 0;
}

.component-1 .DSC-copy-5 {
  object-fit: cover;
  width: 1450px;
  height: 1016px;
  position: absolute;
  top: 0;
  left: 0;
}

.component-1 .DSC-copy-6 {
  object-fit: cover;
  width: 494px;
  height: 756px;
  position: absolute;
  top: 155px;
  left: 473px;
}

.component-1 .IMG {
  object-fit: cover;
  width: 285px;
  height: 429px;
  position: absolute;
  top: 356px;
  left: 989px;
}

.component-1 .IMG-2 {
  object-fit: cover;
  width: 151px;
  height: 429px;
  position: absolute;
  top: 356px;
  left: 1299px;
}

.component-1 .IMG-3 {
  object-fit: cover;
  width: 138px;
  height: 429px;
  position: absolute;
  top: 356px;
  left: 0;
}

.component-1 .IMG-4 {
  object-fit: cover;
  width: 286px;
  height: 429px;
  position: absolute;
  top: 356px;
  left: 163px;
}

.component-1 .frame-10 {
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  position: absolute;
  top: 200px;
  left: 88px;
}

.component-1 .rasum {
  color: #fff;
  letter-spacing: 0;
  margin-top: -1px;
  font-family: Charter-Bold, Helvetica;
  font-size: 40px;
  font-weight: 700;
  line-height: 56.2px;
  position: relative;
}

.component-1 .a-day-in-the-life-of {
  color: #fff;
  letter-spacing: 0;
  width: 334px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-weight: 400;
  position: relative;
}

.component-1 .text-wrapper-21 {
  color: #fff;
  letter-spacing: 0;
  width: 334px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.5px;
  position: absolute;
  top: 821px;
  left: 989px;
}

.component-1 .frame-163004 {
  width: 269px;
}

.component-1 .frame-163005 {
  width: 334px;
}

.component-1 .property-1-frame-163004 {
  font-size: 16px;
  line-height: 22.5px;
}

.component-1 .property-1-frame-163005 {
  font-size: 20px;
  line-height: 28.1px;
}

.component-10 {
  background-image: url("dsc-5698-1-2.8aa47d1a.png");
  background-position: 50%;
  background-size: cover;
  width: 795px;
  height: 529px;
  position: relative;
}

.component-10:hover {
  background-image: url("dsc-5698-1-1.e5d0dc54.png");
}

.component-10 .text-wrapper-8 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 29px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 28.8px;
  position: absolute;
  top: 32px;
  left: 447px;
}

.component-10:hover .text-wrapper-8 {
  top: 30px;
}

.component-10 .text-wrapper-9 {
  color: #fff;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  height: 17px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: absolute;
  top: 65px;
  left: 665px;
}

.component-10:hover .text-wrapper-9 {
  top: 63px;
}

.component-11 {
  background-image: url("img-5242-1-1-2.4f4eb2d2.png");
  background-size: 100% 100%;
  width: 450px;
  height: 434px;
  position: relative;
}

.component-11:hover {
  background-image: url("img-5242-1-1-1.ed13498d.png");
  background-position: 50%;
  background-size: cover;
}

.component-11 .text-wrapper-12 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 29px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 28.8px;
  position: absolute;
  top: 22px;
  left: 249px;
}

.component-11 .text-wrapper-13 {
  color: #fff;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  height: 17px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: absolute;
  top: 55px;
  left: 280px;
}

.component-12 {
  background-image: url("6-31640318-2.3cc5fb5f.png");
  background-position: 50%;
  background-size: cover;
  width: 383px;
  height: 578px;
  position: relative;
}

.component-12:hover {
  background-image: url("6-31640318-1.6d18200a.png");
}

.component-12 .text-wrapper-5 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 29px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 28.8px;
  position: absolute;
  top: 23px;
  left: 157px;
}

.component-12:hover .text-wrapper-5 {
  top: 24px;
}

.component-12 .p {
  color: #fff;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  height: 17px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: absolute;
  top: 56px;
  left: 187px;
}

.component-12:hover .p {
  top: 57px;
}

.component-13 {
  background-image: url("6-31640319-2.fe87f199.png");
  background-position: 50%;
  background-size: cover;
  width: 384px;
  height: 578px;
  position: relative;
}

.component-13:hover {
  background-image: url("6-31640319-1.6ece215b.png");
}

.component-13 .text-wrapper-10 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 29px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 28.8px;
  position: absolute;
  top: 27px;
  left: 198px;
}

.component-13:hover .text-wrapper-10 {
  top: 32px;
}

.component-13 .text-wrapper-11 {
  color: #fff;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  height: 17px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: absolute;
  top: 60px;
  left: 292px;
}

.component-13:hover .text-wrapper-11 {
  top: 65px;
}

.component-14 {
  width: 584px;
  height: 930px;
  position: relative;
  overflow: hidden;
}

.component-14 .group-5 {
  width: 304px;
  height: 2761px;
  position: absolute;
  left: 0;
}

.component-14 .HN {
  object-fit: cover;
  width: 304px;
  position: absolute;
  top: 937px;
  left: 0;
}

.component-14 .MG-copy {
  object-fit: cover;
  width: 304px;
  position: absolute;
}

.component-14 .MG-copy-2 {
  object-fit: cover;
  width: 304px;
  height: 460px;
  position: absolute;
  top: 2575px;
  left: -5931px;
}

.component-14 .MG-copy-3 {
  object-fit: cover;
  width: 304px;
  position: absolute;
}

.component-14 .element {
  object-fit: cover;
  width: 304px;
  height: 406px;
  position: absolute;
  top: 3045px;
  left: -5931px;
}

.component-14 .element-2 {
  object-fit: cover;
  width: 304px;
  position: absolute;
  left: 0;
}

.component-14 .group-6 {
  width: 270px;
  height: 2083px;
  position: absolute;
  left: 314px;
}

.component-14 .element-3 {
  object-fit: cover;
  width: 270px;
  position: absolute;
  top: 832px;
  left: 0;
}

.component-14 .element-4 {
  object-fit: cover;
  width: 270px;
  position: absolute;
  left: 0;
}

.component-14 .element-5 {
  object-fit: cover;
  width: 270px;
  height: 406px;
  position: absolute;
}

.component-14 .element-6 {
  object-fit: cover;
  width: 270px;
  position: absolute;
}

.component-14 .element-7 {
  object-fit: cover;
  width: 270px;
  height: 419px;
  position: absolute;
  top: 2354px;
  left: -5931px;
}

.component-14 .frame-162968 {
  top: -889px;
}

.component-14 .frame-162969 {
  top: -89px;
}

.component-14 .property-1-3-frame-162968 {
  top: 9px;
}

.component-14 .property-1-3-frame-162969 {
  top: -691px;
}

.component-14 .group-5.frame-162968 .HN {
  height: 468px;
}

.component-14 .group-5.frame-162969 .HN {
  height: 82px;
}

.component-14 .group-5.frame-162968 .MG-copy {
  height: 404px;
  top: 1415px;
  left: 0;
}

.component-14 .group-5.frame-162969 .MG-copy {
  height: 460px;
  top: 2105px;
  left: -5931px;
}

.component-14 .group-5.frame-162968 .MG-copy-3 {
  height: 460px;
  top: 690px;
  left: -5931px;
}

.component-14 .group-5.frame-162969 .MG-copy-3 {
  height: 371px;
  top: 89px;
  left: 0;
}

.component-14 .group-5.frame-162968 .element-2 {
  height: 38px;
  top: 889px;
}

.component-14 .group-5.frame-162969 .element-2 {
  height: 457px;
  top: 470px;
}

.component-14 .group-6.property-1-3-frame-162968 .element-3 {
  height: 89px;
}

.component-14 .group-6.property-1-3-frame-162969 .element-3 {
  height: 406px;
}

.component-14 .group-6.property-1-3-frame-162968 .element-4 {
  height: 406px;
  top: 416px;
}

.component-14 .group-6.property-1-3-frame-162969 .element-4 {
  height: 131px;
  top: 691px;
}

.component-14 .group-6.property-1-3-frame-162968 .element-5 {
  top: 0;
  left: 0;
}

.component-14 .group-6.property-1-3-frame-162969 .element-5 {
  top: 690px;
  left: -5931px;
}

.component-14 .group-6.property-1-3-frame-162968 .element-6 {
  height: 406px;
  top: 1938px;
  left: -5931px;
}

.component-14 .group-6.property-1-3-frame-162969 .element-6 {
  height: 373px;
  top: 1248px;
  left: 0;
}

.component-26 {
  width: 390px;
  height: 67px;
  overflow: hidden;
}

.component-26 .group-7 {
  height: 480px;
  position: relative;
}

.component-26 .we-conceptualise {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 48px;
  line-height: 67.4px;
  position: absolute;
  top: 0;
}

.component-26 .span {
  color: #fff;
}

.component-26 .text-wrapper-14 {
  color: #ffebdd;
}

.component-26 .we-curate {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 48px;
  line-height: 67.4px;
  position: absolute;
  top: 92px;
}

.component-26 .we-collaborate {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 48px;
  line-height: 67.4px;
  position: absolute;
  top: 199px;
}

.component-26 .we-create {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 48px;
  line-height: 67.4px;
  position: absolute;
  top: 306px;
}

.component-26 .we-are-passionate {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 48px;
  line-height: 67.4px;
  position: absolute;
  top: 413px;
  left: 0;
}

.component-26 .frame-162993 {
  width: 400px;
  top: -304px;
}

.component-26 .frame-162991 {
  width: 400px;
  top: -92px;
}

.component-26 .frame-162994 {
  width: 402px;
  top: -413px;
  left: -1px;
}

.component-26 .frame-162992 {
  width: 400px;
  top: -199px;
}

.component-26 .frame-162990 {
  width: 400px;
}

.component-26 .group-7.frame-162993 .we-conceptualise, .component-26 .group-7.frame-162991 .we-conceptualise {
  font-family: Proxima Nova-Regular, Helvetica;
  font-weight: 400;
  left: 4px;
}

.component-26 .group-7.frame-162994 .we-conceptualise {
  font-family: Proxima Nova-Regular, Helvetica;
  font-weight: 400;
  left: 5px;
}

.component-26 .group-7.frame-162992 .we-conceptualise {
  font-family: Proxima Nova-Regular, Helvetica;
  font-weight: 400;
  left: 4px;
}

.component-26 .group-7.frame-162990 .we-conceptualise {
  font-family: Proxima Nova-Medium, Helvetica;
  font-weight: 500;
  left: 3px;
}

.component-26 .group-7.frame-162993 .we-curate {
  font-family: Proxima Nova-Regular, Helvetica;
  font-weight: 400;
  left: 84px;
}

.component-26 .group-7.frame-162991 .we-curate {
  font-family: Proxima Nova-Medium, Helvetica;
  font-weight: 500;
  left: 84px;
}

.component-26 .group-7.frame-162994 .we-curate {
  font-family: Proxima Nova-Regular, Helvetica;
  font-weight: 400;
  left: 85px;
}

.component-26 .group-7.frame-162992 .we-curate, .component-26 .group-7.frame-162990 .we-curate {
  font-family: Proxima Nova-Regular, Helvetica;
  font-weight: 400;
  left: 84px;
}

.component-26 .group-7.frame-162993 .we-collaborate, .component-26 .group-7.frame-162991 .we-collaborate {
  font-family: Proxima Nova-Regular, Helvetica;
  font-weight: 400;
  left: 33px;
}

.component-26 .group-7.frame-162994 .we-collaborate {
  font-family: Proxima Nova-Regular, Helvetica;
  font-weight: 400;
  left: 34px;
}

.component-26 .group-7.frame-162992 .we-collaborate {
  font-family: Proxima Nova-Medium, Helvetica;
  font-weight: 500;
  left: 32px;
}

.component-26 .group-7.frame-162990 .we-collaborate {
  font-family: Proxima Nova-Regular, Helvetica;
  font-weight: 400;
  left: 33px;
}

.component-26 .group-7.frame-162993 .we-create {
  font-family: Proxima Nova-Medium, Helvetica;
  font-weight: 500;
  left: 84px;
}

.component-26 .group-7.frame-162991 .we-create {
  font-family: Proxima Nova-Regular, Helvetica;
  font-weight: 400;
  left: 84px;
}

.component-26 .group-7.frame-162994 .we-create {
  font-family: Proxima Nova-Regular, Helvetica;
  font-weight: 400;
  left: 85px;
}

.component-26 .group-7.frame-162992 .we-create, .component-26 .group-7.frame-162990 .we-create {
  font-family: Proxima Nova-Regular, Helvetica;
  font-weight: 400;
  left: 84px;
}

.component-26 .group-7.frame-162993 .we-are-passionate, .component-26 .group-7.frame-162991 .we-are-passionate {
  font-family: Proxima Nova-Regular, Helvetica;
  font-weight: 400;
}

.component-26 .group-7.frame-162994 .we-are-passionate {
  font-family: Proxima Nova-Medium, Helvetica;
  font-weight: 500;
}

.component-26 .group-7.frame-162992 .we-are-passionate, .component-26 .group-7.frame-162990 .we-are-passionate {
  font-family: Proxima Nova-Regular, Helvetica;
  font-weight: 400;
}

.component-27 {
  width: 1264px;
  height: 1037px;
  position: relative;
}

.component-27 .DSC-copy {
  object-fit: cover;
  width: 741px;
  height: 1037px;
  position: absolute;
  top: 0;
  left: 523px;
}

.component-27 .DSC-copy-2 {
  object-fit: cover;
  width: 488px;
  height: 533px;
  position: absolute;
  top: 504px;
  left: 0;
}

.component-33 {
  width: 1277px;
  height: 1175px;
  position: relative;
}

.component-33 .overlap-group-2 {
  width: 1267px;
  height: 1037px;
  position: absolute;
  top: 138px;
  left: 10px;
}

.component-33 .component-2 {
  width: 1264px;
  height: 1037px;
  position: absolute;
  top: 0;
  left: 3px;
}

.component-33 .DSC-copy-3 {
  object-fit: cover;
  width: 741px;
  height: 1037px;
  position: absolute;
  top: 0;
  left: 523px;
}

.component-33 .DSC-copy-4 {
  object-fit: cover;
  width: 488px;
  height: 533px;
  position: absolute;
  top: 504px;
  left: 0;
}

.component-33 .frame-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 97px;
  display: inline-flex;
  position: absolute;
  top: 47px;
  left: 0;
}

.component-33 .frame-3 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 35px;
  display: inline-flex;
  position: relative;
}

.component-33 .editorial-styling {
  color: #000;
  letter-spacing: 0;
  width: 429px;
  margin-top: -1px;
  font-family: Charter-Bold, Helvetica;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  position: relative;
}

.component-33 .editorialist-fuses {
  color: #000;
  letter-spacing: 0;
  width: 429px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.5px;
  position: relative;
}

.component-33 .group-8 {
  width: 192px;
  height: 22px;
  position: relative;
}

.component-33 .text-wrapper-15 {
  color: #000;
  letter-spacing: 0;
  width: 165px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.5px;
  text-decoration: underline;
  position: absolute;
  top: 0;
  left: 0;
}

.component-33 .ellipse-3 {
  background-color: #000;
  border-radius: 9px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 2px;
  left: 172px;
}

.component-33 .frame-4 {
  align-items: flex-start;
  gap: 74px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.component-33 .frame-5 {
  flex: none;
  align-items: flex-start;
  gap: 10px;
  padding: 10px;
  display: inline-flex;
  position: relative;
}

.component-33 .text-wrapper-16 {
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Charter-Bold, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  position: relative;
}

.component-33 .frame-6 {
  flex: none;
  align-items: flex-start;
  gap: 10px;
  padding: 10px;
  display: inline-flex;
  position: relative;
}

.component-33 .text-wrapper-17 {
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Charter-Bold, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  position: relative;
}

.component-33 .frame-7 {
  flex: none;
  align-items: flex-start;
  gap: 10px;
  padding: 10px;
  display: inline-flex;
  position: relative;
}

.component-33 .text-wrapper-18 {
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Charter-Bold, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  position: relative;
}

.component-33 .frame-8 {
  flex: none;
  align-items: flex-start;
  gap: 10px;
  padding: 10px;
  display: inline-flex;
  position: relative;
}

.component-33 .text-wrapper-19 {
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Charter-Bold, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  position: relative;
}

.component-33 .frame-9 {
  flex: none;
  align-items: flex-start;
  gap: 10px;
  padding: 10px;
  display: inline-flex;
  position: relative;
}

.component-33 .text-wrapper-20 {
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Charter-Bold, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  position: relative;
}

.component-33 .frame-163026, .component-33 .frame-163023, .component-33 .frame-163024, .component-33 .frame-163022 {
  font-family: Proxima Nova-Regular, Helvetica;
}

.component-33 .frame-163025 {
  font-family: Charter-Roman, Helvetica;
}

.component-33 .property-1-frame-163022, .component-33 .property-1-frame-163023, .component-33 .property-1-frame-163024, .component-33 .property-1-frame-163025, .component-33 .property-1-frame-163026 {
  border-color: #143b35;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.component-33 .frame-5.property-1-frame-163026 .text-wrapper-16, .component-33 .frame-5.property-1-frame-163023 .text-wrapper-16, .component-33 .frame-5.property-1-frame-163024 .text-wrapper-16 {
  color: #888;
}

.component-33 .frame-5.property-1-frame-163022 .text-wrapper-16 {
  color: #143b35;
}

.component-33 .frame-5.property-1-frame-163025 .text-wrapper-16, .component-33 .frame-6.property-1-frame-163026 .text-wrapper-17 {
  color: #888;
}

.component-33 .frame-6.property-1-frame-163023 .text-wrapper-17 {
  color: #143b35;
}

.component-33 .frame-6.property-1-frame-163024 .text-wrapper-17, .component-33 .frame-6.property-1-frame-163022 .text-wrapper-17, .component-33 .frame-6.property-1-frame-163025 .text-wrapper-17, .component-33 .frame-7.property-1-frame-163026 .text-wrapper-18, .component-33 .frame-7.property-1-frame-163023 .text-wrapper-18 {
  color: #888;
}

.component-33 .frame-7.property-1-frame-163024 .text-wrapper-18 {
  color: #143b35;
}

.component-33 .frame-7.property-1-frame-163022 .text-wrapper-18, .component-33 .frame-7.property-1-frame-163025 .text-wrapper-18, .component-33 .frame-8.property-1-frame-163026 .text-wrapper-19, .component-33 .frame-8.property-1-frame-163023 .text-wrapper-19, .component-33 .frame-8.property-1-frame-163024 .text-wrapper-19, .component-33 .frame-8.property-1-frame-163022 .text-wrapper-19 {
  color: #888;
}

.component-33 .frame-8.property-1-frame-163025 .text-wrapper-19, .component-33 .frame-9.property-1-frame-163026 .text-wrapper-20 {
  color: #143b35;
}

.component-33 .frame-9.property-1-frame-163023 .text-wrapper-20, .component-33 .frame-9.property-1-frame-163024 .text-wrapper-20, .component-33 .frame-9.property-1-frame-163022 .text-wrapper-20, .component-33 .frame-9.property-1-frame-163025 .text-wrapper-20 {
  color: #888;
}

.component-9 {
  background-image: url("mg-7905-copy-1-2.116d229a.png");
  background-position: 50%;
  background-size: cover;
  width: 449px;
  height: 673px;
  position: relative;
}

.component-9:hover {
  background-image: url("mg-7905-copy-1-1.df7414e2.png");
}

.component-9 .text-wrapper-6 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 29px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 28.8px;
  position: absolute;
  top: 35px;
  left: 258px;
}

.component-9:hover .text-wrapper-6 {
  top: 34px;
}

.component-9 .text-wrapper-7 {
  color: #fff;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  height: 17px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  position: absolute;
  top: 68px;
  left: 319px;
}

.component-9:hover .text-wrapper-7 {
  top: 67px;
}

.div-wrapper {
  width: 114px;
  height: 22px;
}

.div-wrapper .group-4 {
  width: 116px;
  height: 22px;
  position: relative;
}

.div-wrapper .text-wrapper-4 {
  letter-spacing: 0;
  width: 96px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.5px;
  text-decoration: underline;
  position: absolute;
  top: 0;
  left: 0;
}

.div-wrapper .ellipse-2 {
  background-color: #fff;
  border-radius: 9px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 3px;
  left: 96px;
}

.div-wrapper .img {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 3px;
  left: 96px;
}

.div-wrapper .frame-162983 {
  color: #fff;
}

.div-wrapper .frame-162984 {
  color: #e9a97d;
}

.property-component-wrapper {
  width: 797px;
  height: 669px;
}

.property-component-wrapper .rectangle {
  width: 797px;
  height: 669px;
  position: absolute;
  top: 0;
  left: 0;
}

.property-component-wrapper .overlap-group {
  width: 983px;
  height: 710px;
  position: relative;
  left: -93px;
}

.property-component-wrapper .rectangle-wrapper {
  background-color: #0000009e;
  background-image: url("component-27-1.237417c0.png");
  background-position: 50%;
  background-size: cover;
  width: 797px;
  height: 669px;
  position: absolute;
  top: 0;
  left: 93px;
}

.property-component-wrapper .div {
  width: 983px;
  height: 710px;
  position: absolute;
  top: 0;
  left: 0;
}

.property-component-wrapper .group {
  width: 335px;
  height: 307px;
  position: absolute;
  top: 64px;
  left: 172px;
}

.property-component-wrapper .group-2 {
  width: 337px;
  height: 307px;
  position: relative;
}

.property-component-wrapper .frame {
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  position: absolute;
  top: 115px;
  left: 2px;
}

.property-component-wrapper .text-wrapper {
  color: #fff;
  letter-spacing: 0;
  width: 333px;
  margin-top: -1px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.5px;
  position: relative;
}

.property-component-wrapper .text-wrapper-2 {
  color: #fff;
  letter-spacing: 0;
  font-family: Charter-Roman, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.property-component-wrapper .vector {
  object-fit: cover;
  width: 320px;
  height: 1px;
  position: absolute;
  top: 74px;
  left: 0;
}

.property-component-wrapper .group-3 {
  width: 108px;
  height: 22px;
  position: absolute;
  top: 285px;
  left: 0;
}

.property-component-wrapper .text-wrapper-3 {
  color: #fff;
  letter-spacing: 0;
  width: 87px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.5px;
  text-decoration: underline;
  position: absolute;
  top: 0;
  left: 0;
}

.property-component-wrapper .ellipse {
  background-color: #fff;
  border-radius: 9px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 4px;
  left: 88px;
}

.property-component-wrapper.property-1-component-25 {
  background-color: #0000009e;
  background-image: url("property-1-component-25.e918eb07.png");
  background-position: 50%;
  background-size: cover;
  position: relative;
}

.property-component-wrapper.property-1-component-26 {
  overflow: hidden;
}

.property-frame-wrapper {
  width: 601px;
  height: 355px;
}

.property-frame-wrapper.frame-162973 {
  background-image: url("rectangle-383-2.fabfe55a.png");
  background-size: 100% 100%;
}

.property-frame-wrapper.frame-162972 {
  background-image: url("rectangle-383-3.bd91e783.png");
  background-size: 100% 100%;
}

.property-frame-wrapper.frame-162974 {
  background-image: url("rectangle-383-1.bec42c4a.png");
  background-position: 50%;
  background-size: cover;
}

.vykhari-case-study {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

body {
  overflow-x: hidden;
}

.vykhari-case-study .div-2 {
  background-color: #fff;
  width: 1920px;
  height: 17024px;
  position: relative;
}

.vykhari-case-study .overlap {
  width: 1933px;
  height: 9059px;
  position: absolute;
  top: 969px;
  left: -13px;
}

.vykhari-case-study .rectangle-3 {
  background-color: #f3fffd;
  width: 1920px;
  height: 7359px;
  position: absolute;
  top: 473px;
  left: 13px;
}

.vykhari-case-study .the-challenge {
  background-color: #fff5e2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 1920px;
  padding: 80px 120px;
  display: flex;
  position: absolute;
  top: 0;
  left: 13px;
}

.vykhari-case-study .frame-wrapper {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 48px;
  width: 100%;
  display: flex;
  position: relative;
}

.vykhari-case-study .frame-11 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  width: 100%;
  display: flex;
  position: relative;
}

.vykhari-case-study .text-wrapper-22 {
  color: #000;
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 40px;
  font-weight: 500;
  line-height: 56px;
  position: relative;
}

.vykhari-case-study .frame-12 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.vykhari-case-study .in-a-world-where {
  color: #000;
  letter-spacing: 0;
  width: 657px;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 44.8px;
  position: relative;
}

.vykhari-case-study .text-wrapper-23 {
  color: #000;
  letter-spacing: 0;
  width: 631px;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 44.8px;
  position: relative;
}

.vykhari-case-study .final {
  background-color: #fff;
  border: 16px solid #0000;
  border-image: linear-gradient(#a2a2a2, #a2a2a200) 1;
  border-radius: 12px;
  width: 1472px;
  height: 7192px;
  position: absolute;
  top: 622px;
  left: 224px;
  overflow: hidden;
}

.vykhari-case-study .overlap-2 {
  width: 1469px;
  height: 969px;
  position: absolute;
  top: 1010px;
  left: 0;
}

.vykhari-case-study .rectangle-4 {
  background-color: #ffebdd;
  width: 1441px;
  height: 969px;
  position: absolute;
  top: 0;
  left: 0;
}

.vykhari-case-study .component-instance {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.vykhari-case-study .component-27-instance {
  background-image: url("component-27.31b29ab5.png") !important;
  height: 592px !important;
  position: absolute !important;
  top: 0 !important;
  left: 644px !important;
}

.vykhari-case-study .design-component-instance-node {
  width: 796px !important;
  height: 592px !important;
}

.vykhari-case-study .rectangle-5 {
  object-fit: cover;
  width: 644px;
  height: 370px;
  position: absolute;
  top: 599px;
  left: 0;
}

.vykhari-case-study .rectangle-6 {
  background-color: #fff5e2;
  width: 396px;
  height: 377px;
  position: absolute;
  top: 592px;
  left: 644px;
}

.vykhari-case-study .xa {
  width: 396px;
  height: 247px;
  position: absolute;
  top: 592px;
  left: 644px;
}

.vykhari-case-study .rectangle-7 {
  background-color: #143b35;
  width: 429px;
  height: 377px;
  position: absolute;
  top: 592px;
  left: 1040px;
}

.vykhari-case-study .frame-13 {
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  position: absolute;
  top: 792px;
  left: 671px;
}

.vykhari-case-study .text-wrapper-24 {
  color: #fff;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Charter-Roman, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.vykhari-case-study .text-wrapper-25 {
  color: #143b35;
  letter-spacing: 0;
  width: 349px;
  font-family: Charter-Roman, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.5px;
  position: absolute;
  top: 861px;
  left: 671px;
}

.vykhari-case-study .frame-14 {
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
  display: inline-flex;
  position: absolute;
  top: 697px;
  left: 71px;
}

.vykhari-case-study .frame-15 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.vykhari-case-study .text-wrapper-26 {
  color: #fff;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Charter-Roman, Helvetica;
  font-size: 36px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.vykhari-case-study .text-wrapper-27 {
  color: #fff;
  letter-spacing: 0;
  width: 365px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.5px;
  position: relative;
}

.vykhari-case-study .group-9 {
  width: 107px;
  height: 22px;
  position: relative;
}

.vykhari-case-study .text-wrapper-28 {
  color: #fff;
  letter-spacing: 0;
  width: 87px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.5px;
  text-decoration: underline;
  position: absolute;
  top: 0;
  left: 0;
}

.vykhari-case-study .ellipse-4 {
  background-color: #fff;
  border-radius: 9px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 4px;
  left: 87px;
}

.vykhari-case-study .group-10 {
  width: 112px;
  height: 22px;
  position: absolute;
  top: 923px;
  left: 671px;
}

.vykhari-case-study .text-wrapper-29 {
  color: #143b35;
  letter-spacing: 0;
  width: 87px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.5px;
  text-decoration: underline;
  position: absolute;
  top: 0;
  left: 0;
}

.vykhari-case-study .ellipse-5 {
  background-color: #143b35;
  border-radius: 9px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 4px;
  left: 92px;
}

.vykhari-case-study .frame-16 {
  flex-direction: column;
  align-items: flex-start;
  gap: 45px;
  width: 309px;
  display: flex;
  position: absolute;
  top: 674px;
  left: 1093px;
}

.vykhari-case-study .frame-17 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  width: 295px;
  display: flex;
  position: relative;
}

.vykhari-case-study .text-wrapper-30 {
  color: #fff;
  letter-spacing: 0;
  width: 273px;
  margin-top: -1px;
  font-family: Charter-Roman, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.vykhari-case-study .text-wrapper-31 {
  color: #fff;
  letter-spacing: 0;
  width: 295px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.5px;
  position: relative;
}

.vykhari-case-study .group-11 {
  width: 108px;
  height: 22px;
  position: relative;
}

.vykhari-case-study .ellipse-6 {
  background-color: #fff;
  border-radius: 9px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 4px;
  left: 88px;
}

.vykhari-case-study .frame-18 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
  position: absolute;
  top: 95px;
  left: 58px;
}

.vykhari-case-study .commercial-styling-e {
  color: #fff;
  letter-spacing: 0;
  width: 424px;
  margin-top: -1px;
  font-family: Charter-Roman, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.vykhari-case-study .text-wrapper-32 {
  color: #303030;
  letter-spacing: -.6px;
  width: 373px;
  font-family: Livvic, Helvetica;
  font-size: 40px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 2659px;
  left: -924px;
}

.vykhari-case-study .overlap-3 {
  background-color: #143b35;
  width: 1441px;
  height: 800px;
  position: absolute;
  top: 0;
  left: 0;
}

.vykhari-case-study .component-22 {
  background-image: url("rectangle-383.42d41f84.png") !important;
  width: 664px !important;
  height: 392px !important;
  position: absolute !important;
  top: 100px !important;
  left: 190px !important;
}

.vykhari-case-study .rectangle-8 {
  object-fit: cover;
  width: 549px;
  height: 700px;
  position: absolute;
  top: 100px;
  left: 889px;
}

.vykhari-case-study .overlap-4 {
  width: 738px;
  height: 224px;
  position: absolute;
  top: 534px;
  left: 88px;
}

.vykhari-case-study .group-12 {
  width: 735px;
  height: 224px;
  position: absolute;
  top: 0;
  left: 3px;
}

.vykhari-case-study .group-13 {
  width: 737px;
  height: 175px;
  position: absolute;
  top: 0;
  left: 0;
}

.vykhari-case-study .text-wrapper-33 {
  color: #fff;
  letter-spacing: 1.04px;
  width: 319px;
  height: 62px;
  font-family: New York-Regular, Helvetica;
  font-size: 52px;
  font-weight: 400;
  line-height: 62.4px;
  position: absolute;
  top: 0;
  left: 0;
}

.vykhari-case-study .frame-19 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 23px;
  width: 534px;
  display: flex;
  position: absolute;
  top: 108px;
  left: 201px;
}

.vykhari-case-study .vector-2 {
  object-fit: cover;
  width: 533px;
  height: 1px;
  margin-top: -.5px;
  position: relative;
}

.vykhari-case-study .frame-20 {
  flex: none;
  align-items: flex-start;
  gap: 173px;
  width: 180px;
  display: flex;
  position: relative;
}

.vykhari-case-study .text-wrapper-34 {
  color: #fff;
  letter-spacing: 0;
  text-align: right;
  width: 176px;
  margin-top: -1px;
  font-family: Charter-Roman, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.5px;
  position: relative;
}

.vykhari-case-study .component-25 {
  position: absolute !important;
  top: 202px !important;
  left: 205px !important;
}

.vykhari-case-study .text-wrapper-35 {
  color: #fff;
  letter-spacing: 0;
  width: 176px;
  height: 44px;
  font-family: Charter-Roman, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.5px;
  position: absolute;
  top: 70px;
  left: 0;
}

.vykhari-case-study .header {
  background-color: #143b35a3;
  align-items: center;
  gap: 697px;
  width: 1440px;
  height: 95px;
  padding: 24px 88px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.vykhari-case-study .vykhari {
  width: 126px;
  height: 77px;
  margin-top: -15px;
  margin-bottom: -15px;
  position: relative;
}

.vykhari-case-study .frame-21 {
  flex: none;
  align-items: center;
  gap: 39px;
  margin-top: -4px;
  margin-bottom: -4px;
  display: inline-flex;
  position: relative;
}

.vykhari-case-study .frame-22 {
  align-items: center;
  width: 69px;
  display: flex;
  position: relative;
}

.vykhari-case-study .text-wrapper-36 {
  color: #fff;
  letter-spacing: -.16px;
  width: 98px;
  margin-top: -1px;
  margin-right: -29px;
  font-family: Proxima Nova-Light, Helvetica;
  font-size: 16px;
  font-weight: 300;
  line-height: 23.4px;
  position: relative;
}

.vykhari-case-study .frame-23 {
  align-items: center;
  width: 67px;
  display: flex;
  position: relative;
}

.vykhari-case-study .text-wrapper-37 {
  color: #fff;
  letter-spacing: -.16px;
  width: 88px;
  margin-top: -1px;
  margin-right: -21px;
  font-family: Proxima Nova-Light, Helvetica;
  font-size: 16px;
  font-weight: 300;
  line-height: 23.4px;
  position: relative;
}

.vykhari-case-study .frame-24 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 29px;
  padding: 10px;
  display: inline-flex;
  position: relative;
}

.vykhari-case-study .text-wrapper-38 {
  color: #fff;
  letter-spacing: -.16px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -8px;
  margin-bottom: -6px;
  font-family: Proxima Nova-Light, Helvetica;
  font-size: 16px;
  font-weight: 300;
  line-height: 23.4px;
  position: relative;
}

.vykhari-case-study .frame-25 {
  border: 1px solid #fff;
  border-radius: 41px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 55px;
  padding: 20px 24px;
  display: inline-flex;
  position: relative;
  box-shadow: 7px 4px 24px #0003;
}

.vykhari-case-study .text-wrapper-39 {
  color: #fff;
  letter-spacing: -.45px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -4.5px;
  margin-bottom: -2.5px;
  font-family: Proxima Nova-Light, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: 21.6px;
  position: relative;
}

.vykhari-case-study .group-14 {
  width: 1262px;
  height: 692px;
  position: absolute;
  top: 2190px;
  left: 89px;
}

.vykhari-case-study .frame-26 {
  height: 692px;
  position: relative;
}

.vykhari-case-study .overlap-5 {
  width: 972px;
  height: 692px;
  position: absolute;
  top: 0;
  left: 0;
}

.vykhari-case-study .rectangle-9 {
  object-fit: cover;
  width: 283px;
  height: 691px;
  position: absolute;
  top: 0;
  left: 689px;
}

.vykhari-case-study .xa-2 {
  width: 690px;
  height: 692px;
  position: absolute;
  top: 0;
  left: 0;
}

.vykhari-case-study .text-wrapper-40 {
  color: #fff;
  letter-spacing: -.72px;
  text-align: right;
  white-space: nowrap;
  height: 86px;
  font-family: Charter-Bold, Helvetica;
  font-size: 72px;
  font-weight: 700;
  line-height: 86.4px;
  position: absolute;
  top: 522px;
  left: 540px;
}

.vykhari-case-study .text-wrapper-41 {
  color: #fff;
  letter-spacing: -.52px;
  text-align: right;
  white-space: nowrap;
  height: 62px;
  font-family: Charter-Bold, Helvetica;
  font-size: 52px;
  font-weight: 700;
  line-height: 62.4px;
  position: absolute;
  top: 534px;
  left: 853px;
}

.vykhari-case-study .text-wrapper-42 {
  color: #fff;
  letter-spacing: -.32px;
  text-align: center;
  white-space: nowrap;
  height: 38px;
  font-family: Charter-Roman, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: absolute;
  top: 618px;
  left: 402px;
}

.vykhari-case-study .line {
  object-fit: cover;
  width: 1px;
  height: 499px;
  position: absolute;
  top: 0;
  left: 630px;
}

.vykhari-case-study .overlap-group-4 {
  background-image: url("rectangle-367.8ad88a8f.png");
  background-position: 50%;
  background-size: cover;
  width: 290px;
  height: 691px;
  position: absolute;
  top: 0;
  left: 972px;
}

.vykhari-case-study .text-wrapper-43 {
  color: #fff;
  letter-spacing: -.52px;
  text-align: right;
  white-space: nowrap;
  height: 62px;
  font-family: Charter-Bold, Helvetica;
  font-size: 52px;
  font-weight: 700;
  line-height: 62.4px;
  position: absolute;
  top: 534px;
  left: 169px;
}

.vykhari-case-study .frame-27 {
  align-items: center;
  gap: 819px;
  display: inline-flex;
  position: absolute;
  top: 2058px;
  left: 89px;
}

.vykhari-case-study .text-wrapper-44 {
  color: #000;
  letter-spacing: -.4px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Charter-Roman, Helvetica;
  font-size: 40px;
  font-weight: 400;
  line-height: 48px;
  position: relative;
}

.vykhari-case-study .frame-28 {
  border: 1px solid #143b35;
  border-radius: 41px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 197px;
  height: 69px;
  padding: 20px 24px;
  display: flex;
  position: relative;
  box-shadow: 7px 4px 24px #0003;
}

.vykhari-case-study .text-wrapper-45 {
  color: #143b35;
  letter-spacing: -.5px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.vykhari-case-study .overlap-6 {
  background-image: url("rectangle-361.0f319208.png");
  background-position: 50%;
  background-size: cover;
  width: 1440px;
  height: 706px;
  position: absolute;
  top: 3897px;
  left: 0;
}

.vykhari-case-study .text-wrapper-46 {
  color: #fff;
  letter-spacing: -.64px;
  width: 593px;
  height: 159px;
  font-family: Charter-Roman, Helvetica;
  font-size: 64px;
  font-weight: 400;
  line-height: 76.8px;
  position: absolute;
  top: 295px;
  left: 85px;
}

.vykhari-case-study .frame-29 {
  align-items: center;
  gap: 733px;
  display: inline-flex;
  position: absolute;
  top: 4673px;
  left: 88px;
}

.vykhari-case-study .text-wrapper-47 {
  color: #000;
  letter-spacing: -.56px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Charter-Roman, Helvetica;
  font-size: 56px;
  font-weight: 400;
  line-height: 67.2px;
  position: relative;
}

.vykhari-case-study .overlap-7 {
  width: 1440px;
  height: 540px;
  position: absolute;
  top: 6037px;
  left: 0;
}

.vykhari-case-study .IMG-5 {
  object-fit: cover;
  width: 1440px;
  height: 496px;
  position: absolute;
  top: 0;
  left: 0;
}

.vykhari-case-study .DSC-wrapper {
  background-color: #927e6c;
  width: 239px;
  height: 355px;
  position: absolute;
  top: 69px;
  left: 472px;
}

.vykhari-case-study .DSC {
  object-fit: cover;
  width: 228px;
  height: 343px;
  position: absolute;
  top: 5px;
  left: 5px;
}

.vykhari-case-study .image {
  width: 1440px;
  height: 233px;
  position: absolute;
  top: 307px;
  left: 0;
}

.vykhari-case-study .img-wrapper {
  background-color: #927e6c;
  width: 239px;
  height: 355px;
  position: absolute;
  top: 69px;
  left: 729px;
}

.vykhari-case-study .experience-the-power {
  color: #fff;
  letter-spacing: -1.3px;
  text-align: center;
  width: 609px;
  height: 123px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 52px;
  font-weight: 400;
  line-height: 52px;
  position: absolute;
  top: 150px;
  left: 394px;
}

.vykhari-case-study .text-wrapper-48 {
  line-height: 62.4px;
}

.vykhari-case-study .text-wrapper-49 {
  line-height: 53.8px;
}

.vykhari-case-study .text-wrapper-50 {
  line-height: 59.3px;
}

.vykhari-case-study .text-wrapper-51 {
  font-family: Inter, Helvetica;
  line-height: 62.4px;
}

.vykhari-case-study .text-wrapper-52 {
  font-family: Charter-Bold, Helvetica;
  font-weight: 700;
  line-height: 62.4px;
}

.vykhari-case-study .frame-30 {
  border: 1px solid #fff;
  border-radius: 41px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 55px;
  padding: 20px 24px;
  display: inline-flex;
  position: absolute;
  top: 322px;
  left: 648px;
  box-shadow: 7px 4px 24px #0003;
}

.vykhari-case-study .component-12-instance {
  position: absolute !important;
  top: 5361px !important;
  left: 553px !important;
}

.vykhari-case-study .component-9-instance {
  position: absolute !important;
  top: 4808px !important;
  left: 87px !important;
}

.vykhari-case-study .component-10-instance {
  position: absolute !important;
  top: 4808px !important;
  left: 553px !important;
}

.vykhari-case-study .component-13-instance {
  position: absolute !important;
  top: 5361px !important;
  left: 964px !important;
}

.vykhari-case-study .component-3 {
  left: 197px !important;
}

.vykhari-case-study .component-11-instance {
  position: absolute !important;
  top: 5503px !important;
  left: 87px !important;
}

.vykhari-case-study .overlap-wrapper {
  background-color: #856649;
  width: 1441px;
  height: 930px;
  position: absolute;
  top: 2967px;
  left: 0;
}

.vykhari-case-study .overlap-8 {
  background-color: #143b35;
  height: 930px;
  position: relative;
}

.vykhari-case-study .frame-31 {
  flex-direction: column;
  align-items: flex-start;
  gap: 27px;
  display: inline-flex;
  position: absolute;
  top: 129px;
  left: 721px;
}

.vykhari-case-study .creating-fashion {
  color: #0000;
  letter-spacing: -.4px;
  width: 577px;
  margin-top: -1px;
  font-family: Charter-Regular, Helvetica;
  font-size: 40px;
  font-weight: 400;
  line-height: 48px;
  position: relative;
}

.vykhari-case-study .text-wrapper-53 {
  color: #fff;
}

.vykhari-case-study .text-wrapper-54 {
  color: #e9a97d;
}

.vykhari-case-study .hi-i-am-ranjitha-a {
  color: #fff;
  letter-spacing: -.81px;
  width: 575px;
  font-family: Proxima Nova-Medium, Helvetica;
  font-size: 18px;
  font-weight: 500;
  line-height: 24.8px;
  position: relative;
}

.vykhari-case-study .component-14-instance {
  top: 12788px !important;
  left: -4233px !important;
}

.vykhari-case-study .component-4 {
  top: 10903px !important;
  left: -4233px !important;
}

.vykhari-case-study .component-5 {
  top: 13258px !important;
  left: -4233px !important;
}

.vykhari-case-study .component-6 {
  top: 12151px !important;
  left: -4233px !important;
}

.vykhari-case-study .component-7 {
  top: 12567px !important;
  left: -4233px !important;
}

.vykhari-case-study .frame-32 {
  flex-direction: column;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 858px;
  left: 392px;
}

.vykhari-case-study .text-wrapper-55 {
  color: #143b35;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Charter-Roman, Helvetica;
  font-size: 48px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.vykhari-case-study .text-wrapper-56 {
  color: #143b35;
  letter-spacing: 0;
  width: 393px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28.1px;
  position: relative;
}

.vykhari-case-study .element-8 {
  object-fit: cover;
  width: 1264px;
  height: 618px;
  position: absolute;
  top: 21567px;
  left: -4145px;
}

.vykhari-case-study .vector-wrapper {
  border: 3px solid #fff;
  border-radius: 27px;
  width: 54px;
  height: 54px;
  position: absolute;
  top: 8006px;
  left: 705px;
}

.vykhari-case-study .vector-3 {
  width: 16px;
  height: 21px;
  position: absolute;
  top: 13880px;
  left: -4214px;
}

.vykhari-case-study .footers {
  background-color: #143b35;
  width: 1440px;
  height: 385px;
  position: absolute;
  top: 8382px;
  left: 0;
  overflow: hidden;
}

.vykhari-case-study .vykhari-2 {
  width: 126px;
  height: 77px;
  position: absolute;
  top: 5557px;
  left: -4113px;
}

.vykhari-case-study .company-links {
  width: 124px;
  height: 203px;
  position: absolute;
  top: 69px;
  left: 730px;
}

.vykhari-case-study .text-wrapper-57 {
  color: var(--white);
  letter-spacing: 0;
  font-family: Proxima Nova-Bold, Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.vykhari-case-study .text-wrapper-58 {
  color: var(--primary-light);
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: absolute;
  top: 46px;
  left: 0;
}

.vykhari-case-study .text-wrapper-59 {
  color: var(--primary-light);
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: absolute;
  top: 90px;
  left: 0;
}

.vykhari-case-study .text-wrapper-60 {
  color: var(--primary-light);
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  position: absolute;
  top: 347px;
  left: 627px;
}

.vykhari-case-study .resources-links {
  width: 129px;
  height: 203px;
  position: absolute;
  top: 69px;
  left: 933px;
}

.vykhari-case-study .product-links {
  width: 112px;
  height: 203px;
  position: absolute;
  top: 69px;
  left: 1137px;
}

.vykhari-case-study .divider {
  object-fit: cover;
  width: 1440px;
  height: 1px;
  position: absolute;
  top: 5820px;
  left: -4233px;
}

.vykhari-case-study .overlap-group-wrapper {
  width: 1264px;
  height: 992px;
  position: absolute;
  top: 6668px;
  left: 88px;
}

.vykhari-case-study .overlap-9 {
  height: 992px;
  position: relative;
}

.vykhari-case-study .frame-33 {
  width: 1264px;
  height: 992px;
  position: absolute;
  top: 0;
  left: 0;
}

.vykhari-case-study .group-15 {
  width: 1266px;
  height: 644px;
  position: absolute;
  top: 0;
  left: 0;
}

.vykhari-case-study .here-s-how-THE-wrapper {
  background-image: url("dsc-5698-2.796dcb67.png");
  background-position: 50%;
  background-size: cover;
  width: 1264px;
  height: 492px;
  position: absolute;
  top: 0;
  left: 0;
}

.vykhari-case-study .here-s-how-THE {
  color: #fff;
  letter-spacing: -.8px;
  width: 730px;
  height: 77px;
  font-family: Proxima Nova-Medium, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: absolute;
  top: 397px;
  left: 41px;
}

.vykhari-case-study .text-wrapper-61 {
  font-weight: 500;
}

.vykhari-case-study .text-wrapper-62 {
  font-family: Charter-Regular, Helvetica;
}

.vykhari-case-study .group-16 {
  width: 506px;
  height: 34px;
  position: absolute;
  top: 492px;
  left: 41px;
}

.vykhari-case-study .text-wrapper-63 {
  color: #fff;
  letter-spacing: -.45px;
  white-space: nowrap;
  height: 22px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.6px;
  position: absolute;
  top: 6px;
  left: 0;
}

.vykhari-case-study .text-wrapper-64 {
  color: #fff;
  letter-spacing: -.45px;
  white-space: nowrap;
  height: 22px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.6px;
  position: absolute;
  top: 6px;
  left: 356px;
}

.vykhari-case-study .line-2 {
  object-fit: cover;
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 318px;
}

.vykhari-case-study .overlap-10 {
  width: 1266px;
  height: 348px;
  position: absolute;
  top: 644px;
  left: 0;
}

.vykhari-case-study .group-17 {
  width: 431px;
  height: 348px;
  position: absolute;
  top: 0;
  left: 0;
}

.vykhari-case-study .element-reasons-to-buy-wrapper {
  background-color: #ddcdbd;
  width: 429px;
  height: 348px;
  position: relative;
}

.vykhari-case-study .element-reasons-to-buy {
  color: #000;
  letter-spacing: -.8px;
  width: 351px;
  height: 152px;
  font-family: Proxima Nova-Medium, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  position: absolute;
  top: 137px;
  left: 29px;
}

.vykhari-case-study .text-wrapper-65 {
  font-family: Charter-Bold, Helvetica;
  font-weight: 700;
}

.vykhari-case-study .group-18 {
  width: 337px;
  height: 348px;
  position: absolute;
  top: 0;
  left: 429px;
}

.vykhari-case-study .element-copy {
  width: 335px;
  height: 175px;
  position: absolute;
  top: 7375px;
  left: -4321px;
}

.vykhari-case-study .the-jewellery-INDIAN-wrapper {
  background-color: #966c49;
  width: 335px;
  height: 173px;
  position: absolute;
  top: 0;
  left: 0;
}

.vykhari-case-study .the-jewellery-INDIAN {
  color: #fff;
  letter-spacing: -.4px;
  width: 202px;
  height: 51px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  position: absolute;
  top: 53px;
  left: 30px;
}

.vykhari-case-study .text-wrapper-66 {
  color: #fff;
  letter-spacing: -.4px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
}

.vykhari-case-study .text-wrapper-67 {
  letter-spacing: -.6px;
  font-size: 24px;
  line-height: 28.8px;
}

.vykhari-case-study .group-19 {
  width: 502px;
  height: 348px;
  position: absolute;
  top: 0;
  left: 764px;
}

.vykhari-case-study .IMG-6 {
  object-fit: cover;
  width: 500px;
  height: 348px;
  position: absolute;
  top: 7202px;
  left: -4321px;
}

.vykhari-case-study .text-wrapper-68 {
  color: #fff;
  letter-spacing: -.6px;
  width: 322px;
  height: 29px;
  font-family: Proxima Nova-Semibold, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 28.8px;
  position: absolute;
  top: 235px;
  left: 29px;
}

.vykhari-case-study .group-20 {
  width: 108px;
  height: 22px;
  position: absolute;
  top: 272px;
  left: 29px;
}

.vykhari-case-study .mask-group {
  width: 344px;
  height: 98px;
  position: absolute;
  top: 7874px;
  left: -4292px;
}

.vykhari-case-study .group-21 {
  width: 108px;
  height: 22px;
  position: absolute;
  top: 519px;
  left: 41px;
}

.vykhari-case-study .group-22 {
  width: 108px;
  height: 22px;
  position: absolute;
  top: 914px;
  left: 33px;
}

.vykhari-case-study .text-wrapper-69 {
  color: #000;
  letter-spacing: 0;
  width: 87px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.5px;
  text-decoration: underline;
  position: absolute;
  top: 0;
  left: 0;
}

.vykhari-case-study .ellipse-7 {
  background-color: #000;
  border-radius: 9px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 4px;
  left: 88px;
}

.vykhari-case-study .group-23 {
  width: 108px;
  height: 22px;
  position: absolute;
  top: 726px;
  left: 459px;
}

.vykhari-case-study .frame-34 {
  background-color: #fff;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  width: 1920px;
  height: 1233px;
  padding: 120px 160px;
  display: flex;
  position: absolute;
  top: 7826px;
  left: 0;
}

.vykhari-case-study .text-wrapper-70 {
  color: #2f2f2f;
  letter-spacing: -.3px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-75Bold, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 38.4px;
  position: relative;
}

.vykhari-case-study .ellipse-8 {
  background-color: #143b35;
  border-radius: 9px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 207px;
  left: 164px;
}

.vykhari-case-study .ellipse-9 {
  background-color: #143b35;
  border-radius: 9px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 376px;
  left: 164px;
}

.vykhari-case-study .ellipse-10 {
  background-color: #143b35;
  border-radius: 9px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 545px;
  left: 164px;
}

.vykhari-case-study .ellipse-11 {
  background-color: #143b35;
  border-radius: 9px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 714px;
  left: 164px;
}

.vykhari-case-study .ellipse-12 {
  background-color: #143b35;
  border-radius: 9px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 882px;
  left: 164px;
}

.vykhari-case-study .ellipse-13 {
  background-color: #143b35;
  border-radius: 9px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 1050px;
  left: 164px;
}

.vykhari-case-study .visually-striking {
  color: #676768;
  letter-spacing: -.3px;
  width: 1608px;
  height: 896px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 40px;
  font-weight: 400;
  line-height: 56px;
  position: absolute;
  top: 189px;
  left: 206px;
}

.vykhari-case-study .overlap-11 {
  width: 1920px;
  height: 969px;
  position: absolute;
  top: 0;
  left: 0;
}

.vykhari-case-study .frame-35 {
  background-color: #143b35;
  flex-direction: column;
  align-items: flex-start;
  gap: 64px;
  width: 1920px;
  height: 969px;
  padding: 120px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.vykhari-case-study .heading-wrapper {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 260px;
  width: 100%;
  display: flex;
  position: relative;
}

.vykhari-case-study .heading {
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  width: 1181px;
  display: flex;
  position: relative;
}

.vykhari-case-study .text-wrapper-71 {
  color: #fff;
  letter-spacing: -.48px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 96px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.vykhari-case-study .digital-branding-web {
  color: #fff;
  letter-spacing: -.16px;
  width: 888px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 48px;
  position: relative;
}

.vykhari-case-study .group-24 {
  width: 448px;
  height: 108px;
  position: relative;
}

.vykhari-case-study .frame-36 {
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 52px;
  left: 0;
}

.vykhari-case-study .UIX {
  width: 252px;
  height: 56px;
  position: relative;
}

.vykhari-case-study .frame-37 {
  background-color: #a7a7a71f;
  border-radius: 28px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  padding: 4px 16px 4px 4px;
  display: inline-flex;
  position: relative;
}

.vykhari-case-study .frame-38 {
  flex: none;
  align-items: center;
  gap: 12px;
  display: inline-flex;
  position: relative;
}

.vykhari-case-study .group-25 {
  background-color: #fff;
  border-radius: 24px;
  width: 48px;
  height: 48px;
  position: relative;
}

.vykhari-case-study .overlap-group-5 {
  width: 22px;
  height: 22px;
  position: relative;
  top: 13px;
  left: 13px;
}

.vykhari-case-study .line-3 {
  width: 5px;
  height: 22px;
  position: absolute;
  top: 0;
  left: 9px;
}

.vykhari-case-study .line-4 {
  width: 22px;
  height: 4px;
  position: absolute;
  top: 9px;
  left: 0;
}

.vykhari-case-study .text-wrapper-72 {
  color: #fff;
  letter-spacing: .46px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Neue Haas Grotesk Display Pro-75Bold, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 32.9px;
  position: relative;
}

.vykhari-case-study .UIX-2 {
  width: 178px;
  height: 56px;
  position: relative;
}

.vykhari-case-study .text-wrapper-73 {
  color: #fff;
  letter-spacing: -.39px;
  white-space: nowrap;
  height: 40px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: 40px;
  position: absolute;
  top: 0;
  left: 0;
}

.vykhari-case-study .macbook-air-mockup {
  width: 1327px;
  height: 969px;
  position: absolute;
  top: 0;
  left: 593px;
}

.vykhari-case-study .element-9 {
  width: 1920px;
  height: 1180px;
  position: absolute;
  top: 10028px;
  left: 2px;
}

.vykhari-case-study .overlap-12 {
  width: 222px;
  height: 37px;
  position: absolute;
  top: -86px;
  left: 120px;
}

.vykhari-case-study .text-wrapper-74 {
  color: #2f2f2f;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Neue Haas Grotesk Display Pro-55Roman, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.vykhari-case-study .frame-39 {
  background-color: #143b35;
  width: 896px;
  height: 1180px;
  position: absolute;
  top: 0;
  left: 1024px;
  overflow: hidden;
}

.vykhari-case-study .overlap-group-6 {
  background-color: #143b35;
  border-radius: 2px;
  width: 961px;
  height: 1180px;
  position: relative;
  left: -65px;
}

.vykhari-case-study .overlap-13 {
  width: 627px;
  height: 769px;
  position: absolute;
  top: 89px;
  left: 191px;
}

.vykhari-case-study .text-wrapper-75 {
  color: #fff;
  letter-spacing: -2.75px;
  white-space: nowrap;
  font-family: Charter-Roman, Helvetica;
  font-size: 549px;
  font-weight: 400;
  line-height: 768.6px;
  position: absolute;
  top: 0;
  left: 0;
}

.vykhari-case-study .text-wrapper-76 {
  color: #fff;
  letter-spacing: -.16px;
  white-space: nowrap;
  font-family: Lexend Deca, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 44.8px;
  position: absolute;
  top: 39px;
  left: 9px;
}

.vykhari-case-study .text-wrapper-77 {
  color: #fff;
  letter-spacing: 0;
  width: 693px;
  font-family: Charter-Roman, Helvetica;
  font-size: 48px;
  font-weight: 400;
  line-height: 67.2px;
  position: absolute;
  top: 858px;
  left: 191px;
}

.vykhari-case-study .frame-40 {
  flex-direction: column;
  align-items: flex-start;
  width: 960px;
  height: 1180px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.vykhari-case-study .frame-41 {
  align-items: flex-start;
  height: 639px;
  display: inline-flex;
  position: relative;
}

.vykhari-case-study .frame-42 {
  background-color: #143b35;
  flex-direction: column;
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  width: 640px;
  padding: 80px 64px;
  display: flex;
  position: relative;
}

.vykhari-case-study .frame-43 {
  flex-direction: column;
  flex: none;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: relative;
}

.vykhari-case-study .text-wrapper-78 {
  color: var(--core-colorswhite);
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.vykhari-case-study .frame-44 {
  flex: none;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: relative;
}

.vykhari-case-study .text-wrapper-79 {
  color: var(--core-colorswhite);
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.vykhari-case-study .frame-45 {
  background-color: #774c24;
  flex-direction: column;
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  width: 320px;
  padding: 80px 64px;
  display: flex;
  position: relative;
}

.vykhari-case-study .frame-46 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: relative;
}

.vykhari-case-study .frame-47 {
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  height: 341px;
  display: flex;
  position: relative;
}

.vykhari-case-study .frame-48 {
  background-color: #c9f9df;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  padding: 64px;
  display: flex;
  position: relative;
}

.vykhari-case-study .text-wrapper-80 {
  color: #000;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.vykhari-case-study .text-wrapper-81 {
  color: #000;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.vykhari-case-study .frame-49 {
  background-color: #fff5e2;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  padding: 64px;
  display: flex;
  position: relative;
}

.vykhari-case-study .frame-50 {
  background-color: #1e131e;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  padding: 64px;
  display: flex;
  position: relative;
}

.vykhari-case-study .text-wrapper-82 {
  color: #fff;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.vykhari-case-study .text-wrapper-83 {
  color: #fff;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.vykhari-case-study .frame-51 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.vykhari-case-study .frame-52 {
  background-color: #485058;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 32px;
  height: 200px;
  padding: 56px 64px;
  display: flex;
  position: relative;
}

.vykhari-case-study .frame-53 {
  background-color: #000;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 32px;
  height: 200px;
  padding: 56px 64px;
  display: flex;
  position: relative;
}

.vykhari-case-study .overlap-14 {
  width: 1920px;
  height: 4734px;
  position: absolute;
  top: 11259px;
  left: 0;
}

.vykhari-case-study .service {
  background-color: #fff;
  border: 10px solid #d3d3d3;
  border-radius: 20px;
  width: 978px;
  height: 3184px;
  position: absolute;
  top: 0;
  left: 53px;
  overflow: hidden;
}

.vykhari-case-study .div-3 {
  width: 971px;
  height: 485px;
  position: absolute;
  top: 0;
  left: 0;
}

.vykhari-case-study .overlap-group-7 {
  height: 485px;
  position: relative;
}

.vykhari-case-study .rectangle-10 {
  object-fit: cover;
  width: 968px;
  height: 485px;
  position: absolute;
  top: 0;
  left: 0;
}

.vykhari-case-study .frame-54 {
  flex-direction: column;
  align-items: flex-start;
  gap: 13.44px;
  display: inline-flex;
  position: absolute;
  top: 243px;
  left: 88px;
}

.vykhari-case-study .editorial-fashion {
  color: #fff;
  letter-spacing: 0;
  width: 295.78px;
  margin-top: -.67px;
  font-family: Charter-Bold, Helvetica;
  font-size: 26.9px;
  font-weight: 700;
  line-height: 32.3px;
  position: relative;
}

.vykhari-case-study .text-wrapper-84 {
  color: #fff;
  letter-spacing: 0;
  font-family: Charter-Bold, Helvetica;
  font-size: 26.9px;
  font-weight: 700;
  line-height: 32.3px;
}

.vykhari-case-study .text-wrapper-85 {
  color: #fff;
  letter-spacing: 0;
  width: 224.52px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  line-height: 15.1px;
  position: relative;
}

.vykhari-case-study .component-25-instance {
  width: 79px !important;
  height: 15px !important;
}

.vykhari-case-study .component-8 {
  width: 65px !important;
  font-size: 10.8px !important;
  line-height: 15.1px !important;
}

.vykhari-case-study .component-15 {
  border-radius: 6.05px !important;
  width: 12px !important;
  height: 12px !important;
  top: 2px !important;
  left: 65px !important;
}

.vykhari-case-study .component-16 {
  width: 76.63px !important;
  height: 14.79px !important;
  position: relative !important;
}

.vykhari-case-study .header-2 {
  align-items: center;
  gap: 468.54px;
  width: 968px;
  height: 64px;
  padding: 16.13px 59.16px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.vykhari-case-study .vykhari-3 {
  width: 84.7px;
  height: 51.76px;
  margin-top: -10.08px;
  margin-bottom: -10.08px;
  position: relative;
}

.vykhari-case-study .frame-55 {
  flex: none;
  align-items: center;
  gap: 26.22px;
  margin-top: -2.69px;
  margin-bottom: -2.69px;
  display: inline-flex;
  position: relative;
}

.vykhari-case-study .frame-56 {
  align-items: center;
  width: 46.38px;
  display: flex;
  position: relative;
}

.vykhari-case-study .text-wrapper-86 {
  color: #fff;
  letter-spacing: -.11px;
  width: 65.88px;
  margin-top: -.67px;
  margin-right: -19.49px;
  font-family: Proxima Nova-Light, Helvetica;
  font-size: 10.8px;
  font-weight: 300;
  line-height: 15.7px;
  position: relative;
}

.vykhari-case-study .frame-57 {
  align-items: center;
  width: 45.04px;
  display: flex;
  position: relative;
}

.vykhari-case-study .text-wrapper-87 {
  color: #fff;
  letter-spacing: -.11px;
  width: 59.16px;
  margin-top: -.67px;
  margin-right: -14.12px;
  font-family: Proxima Nova-Light, Helvetica;
  font-size: 10.8px;
  font-weight: 300;
  line-height: 15.7px;
  position: relative;
}

.vykhari-case-study .frame-58 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 6.72px;
  height: 19.49px;
  padding: 6.72px;
  display: inline-flex;
  position: relative;
}

.vykhari-case-study .text-wrapper-88 {
  color: #fff;
  letter-spacing: -.11px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -5.65px;
  margin-bottom: -4.3px;
  font-family: Proxima Nova-Light, Helvetica;
  font-size: 10.8px;
  font-weight: 300;
  line-height: 15.7px;
  position: relative;
}

.vykhari-case-study .frame-59 {
  border: .67px solid #fff;
  border-radius: 27.56px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 6.72px;
  height: 36.97px;
  padding: 13.44px 16.13px;
  display: inline-flex;
  position: relative;
  box-shadow: 4.71px 2.69px 16.13px #0003;
}

.vykhari-case-study .text-wrapper-89 {
  color: #fff;
  letter-spacing: -.3px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -3.13px;
  margin-bottom: -1.79px;
  font-family: Proxima Nova-Light, Helvetica;
  font-size: 12.1px;
  font-weight: 300;
  line-height: 14.5px;
  position: relative;
}

.vykhari-case-study .text-wrapper-90 {
  color: #303030;
  letter-spacing: -.4px;
  width: 251px;
  font-family: Livvic, Helvetica;
  font-size: 26.9px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 1782px;
  left: -621px;
}

.vykhari-case-study .footers-2 {
  background-color: #143b35;
  width: 968px;
  height: 259px;
  position: absolute;
  top: 4086px;
  left: 0;
  overflow: hidden;
}

.vykhari-case-study .vykhari-4 {
  width: 85px;
  height: 52px;
  position: absolute;
  top: 174px;
  left: -3983px;
}

.vykhari-case-study .company-links-2 {
  width: 83px;
  height: 136px;
  position: absolute;
  top: 46px;
  left: 491px;
}

.vykhari-case-study .text-wrapper-91 {
  color: var(--white);
  letter-spacing: 0;
  font-family: Proxima Nova-Bold, Helvetica;
  font-size: 12.1px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.vykhari-case-study .text-wrapper-92 {
  color: var(--primary-light);
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  line-height: 16.1px;
  position: absolute;
  top: 31px;
  left: 0;
}

.vykhari-case-study .text-wrapper-93 {
  color: var(--primary-light);
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  line-height: 16.1px;
  position: absolute;
  top: 61px;
  left: 0;
}

.vykhari-case-study .text-wrapper-94 {
  color: var(--primary-light);
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 9.4px;
  font-weight: 400;
  line-height: 14.1px;
  position: absolute;
  top: 233px;
  left: 421px;
}

.vykhari-case-study .resources-links-2 {
  width: 87px;
  height: 136px;
  position: absolute;
  top: 46px;
  left: 627px;
}

.vykhari-case-study .product-links-2 {
  width: 75px;
  height: 136px;
  position: absolute;
  top: 46px;
  left: 764px;
}

.vykhari-case-study .divider-2 {
  width: 968px;
  height: 1px;
  position: absolute;
  top: 350px;
  left: -4064px;
}

.vykhari-case-study .group-26 {
  width: 968px;
  height: 341px;
  position: absolute;
  top: 3744px;
  left: 0;
}

.vykhari-case-study .DSC-copy-7 {
  width: 968px;
  height: 341px;
  position: absolute;
  top: 4213px;
  left: -4064px;
}

.vykhari-case-study .frame-60 {
  flex-direction: column;
  align-items: center;
  gap: 23.53px;
  display: inline-flex;
  position: absolute;
  top: 116px;
  left: 153px;
}

.vykhari-case-study .text-wrapper-95 {
  color: #fff;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.67px;
  font-family: Charter-Bold, Helvetica;
  font-size: 35px;
  font-weight: 700;
  line-height: 49.1px;
  position: relative;
}

.vykhari-case-study .frame-61 {
  border: .67px solid #fff;
  border-radius: 27.56px;
  justify-content: center;
  align-items: center;
  gap: 6.72px;
  height: 36.97px;
  padding: 13.44px 16.13px;
  display: inline-flex;
  position: relative;
  box-shadow: 4.71px 2.69px 16.13px #0003;
}

.vykhari-case-study .text-wrapper-96 {
  color: #fff;
  letter-spacing: -.3px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -3.13px;
  margin-bottom: -1.79px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 12.1px;
  font-weight: 400;
  line-height: 14.5px;
  position: relative;
}

.vykhari-case-study .overlap-15 {
  width: 968px;
  height: 417px;
  position: absolute;
  top: 1352px;
  left: 0;
}

.vykhari-case-study .rectangle-11 {
  width: 968px;
  height: 415px;
  position: absolute;
  top: 1px;
  left: 0;
}

.vykhari-case-study .screenshot {
  object-fit: cover;
  width: 348px;
  height: 417px;
  position: absolute;
  top: 0;
  left: 312px;
}

.vykhari-case-study .image-2 {
  object-fit: cover;
  width: 597px;
  height: 415px;
  position: absolute;
  top: 1px;
  left: 312px;
}

.vykhari-case-study .rectangle-12 {
  object-fit: cover;
  width: 312px;
  height: 415px;
  position: absolute;
  top: 1px;
  left: 0;
}

.vykhari-case-study .rectangle-13 {
  object-fit: cover;
  width: 313px;
  height: 415px;
  position: absolute;
  top: 1px;
  left: 655px;
}

.vykhari-case-study .component-26-instance {
  width: 262px !important;
  height: 45px !important;
  position: absolute !important;
  top: 165px !important;
  left: 355px !important;
}

.vykhari-case-study .component-17 {
  width: 272px !important;
  height: 323px !important;
}

.vykhari-case-study .component-18 {
  font-size: 32.3px !important;
  line-height: 45.3px !important;
  left: 2px !important;
}

.vykhari-case-study .component-19 {
  font-size: 32.3px !important;
  line-height: 45.3px !important;
  top: 62px !important;
  left: 56px !important;
}

.vykhari-case-study .component-20 {
  font-size: 32.3px !important;
  line-height: 45.3px !important;
  top: 134px !important;
  left: 22px !important;
}

.vykhari-case-study .component-21 {
  font-size: 32.3px !important;
  line-height: 45.3px !important;
  top: 206px !important;
  left: 55px !important;
}

.vykhari-case-study .component-23 {
  font-size: 32.3px !important;
  line-height: 45.3px !important;
  top: 278px !important;
}

.vykhari-case-study .group-27 {
  width: 73px;
  height: 15px;
  position: absolute;
  top: 235px;
  left: 450px;
}

.vykhari-case-study .text-wrapper-97 {
  color: #fff;
  letter-spacing: 0;
  width: 52px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  line-height: 15.1px;
  text-decoration: underline;
  position: absolute;
  top: 0;
  left: 0;
}

.vykhari-case-study .ellipse-14 {
  background-color: #fff;
  border-radius: 6.05px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 3px;
  left: 59px;
}

.vykhari-case-study .group-wrapper {
  background-image: url("hn-01017-1-1.eb07115b.png");
  background-position: 50%;
  background-size: cover;
  width: 968px;
  height: 442px;
  position: absolute;
  top: 1769px;
  left: 0;
}

.vykhari-case-study .group-28 {
  border: 2.02px solid #fff;
  border-radius: 18.15px;
  width: 36px;
  height: 36px;
  position: relative;
  top: 195px;
  left: 461px;
}

.vykhari-case-study .vector-4 {
  width: 13px;
  height: 16px;
  position: absolute;
  top: 8px;
  left: 12px;
}

.vykhari-case-study .overlap-16 {
  width: 1377px;
  height: 1420px;
  position: absolute;
  top: 2324px;
  left: -204px;
}

.vykhari-case-study .overlap-17 {
  width: 1377px;
  height: 1420px;
  position: absolute;
  top: 1px;
  left: 0;
}

.vykhari-case-study .group-29 {
  background-color: #143b35;
  width: 496px;
  height: 425px;
  position: absolute;
  top: 0;
  left: 204px;
}

.vykhari-case-study .overlap-18 {
  width: 430px;
  height: 380px;
  position: relative;
  top: 22px;
  left: 42px;
}

.vykhari-case-study .HN-2 {
  object-fit: cover;
  width: 286px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 144px;
}

.vykhari-case-study .frame-62 {
  flex-direction: column;
  align-items: flex-start;
  gap: 18.82px;
  width: 210px;
  display: flex;
  position: absolute;
  top: 11px;
  left: 0;
}

.vykhari-case-study .MEN-s-STYLING {
  color: #fff;
  letter-spacing: 0;
  text-align: right;
  width: 180.16px;
  margin-top: -.67px;
  font-family: Charter-Bold, Helvetica;
  font-size: 26.9px;
  font-weight: 700;
  line-height: 27.7px;
  position: relative;
}

.vykhari-case-study .group-30 {
  width: 75.94px;
  height: 15px;
  position: relative;
}

.vykhari-case-study .text-wrapper-98 {
  color: #fff;
  letter-spacing: 0;
  width: 58px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  line-height: 15.1px;
  text-decoration: underline;
  position: absolute;
  top: 0;
  left: 0;
}

.vykhari-case-study .ellipse-15 {
  background-color: #fff;
  border-radius: 6.05px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 3px;
  left: 62px;
}

.vykhari-case-study .overlap-19 {
  width: 1377px;
  height: 996px;
  position: absolute;
  top: 424px;
  left: 0;
}

.vykhari-case-study .group-31 {
  width: 499px;
  height: 425px;
  position: absolute;
  top: 1px;
  left: 204px;
}

.vykhari-case-study .overlap-20 {
  background-image: url("x5a0489-1.b4f540ee.png");
  background-position: 50%;
  background-size: cover;
  width: 497px;
  height: 425px;
  position: relative;
}

.vykhari-case-study .text-wrapper-99 {
  color: #fff;
  letter-spacing: 0;
  text-align: right;
  width: 248px;
  font-family: Charter-Bold, Helvetica;
  font-size: 26.9px;
  font-weight: 700;
  line-height: 27.7px;
  position: absolute;
  top: 309px;
  left: 225px;
}

.vykhari-case-study .group-32 {
  width: 87px;
  height: 15px;
  position: absolute;
  top: 350px;
  left: 385px;
}

.vykhari-case-study .text-wrapper-100 {
  color: #fff;
  letter-spacing: 0;
  width: 68px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  line-height: 15.1px;
  text-decoration: underline;
  position: absolute;
  top: 0;
  left: 0;
}

.vykhari-case-study .ellipse-16 {
  background-color: #fff;
  border-radius: 6.05px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 3px;
  left: 72px;
}

.vykhari-case-study .ellipse-17 {
  border: .67px solid #fff;
  border-radius: 150.92px / 129.07px;
  width: 302px;
  height: 258px;
  position: absolute;
  top: 23px;
  left: 65px;
}

.vykhari-case-study .element-10 {
  background-color: #dfcebb;
  width: 471px;
  height: 425px;
  position: absolute;
  top: 0;
  left: 701px;
}

.vykhari-case-study .group-33 {
  width: 84px;
  height: 15px;
  position: absolute;
  top: 387px;
  left: 1066px;
}

.vykhari-case-study .text-wrapper-101 {
  color: #143b35;
  letter-spacing: 0;
  width: 65px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  line-height: 15.1px;
  text-decoration: underline;
  position: absolute;
  top: 0;
  left: 0;
}

.vykhari-case-study .ellipse-18 {
  background-color: #143b35;
  border-radius: 6.71px / 6.05px;
  width: 13px;
  height: 12px;
  position: absolute;
  top: 3px;
  left: 69px;
}

.vykhari-case-study .e-COMMERCE-styling {
  color: #143b35;
  letter-spacing: 0;
  text-align: right;
  width: 255px;
  font-family: Charter-Bold, Helvetica;
  font-size: 26.9px;
  font-weight: 700;
  line-height: 37.8px;
  position: absolute;
  top: 29px;
  left: 821px;
}

.vykhari-case-study .text-wrapper-102 {
  color: #143b35;
  letter-spacing: 0;
  font-family: Charter-Bold, Helvetica;
  font-size: 26.9px;
  font-weight: 700;
  line-height: 37.8px;
}

.vykhari-case-study .text-wrapper-103 {
  color: #143b35;
  letter-spacing: 0;
  text-align: right;
  width: 12px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  line-height: 15.1px;
  position: absolute;
  top: 267px;
  left: 724px;
}

.vykhari-case-study .text-wrapper-104 {
  color: #143b35;
  letter-spacing: 0;
  text-align: right;
  width: 15px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  line-height: 15.1px;
  position: absolute;
  top: 363px;
  left: 847px;
}

.vykhari-case-study .text-wrapper-105 {
  color: #143b35;
  letter-spacing: 0;
  text-align: right;
  width: 14px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  line-height: 15.1px;
  position: absolute;
  top: 267px;
  left: 1040px;
}

.vykhari-case-study .element-11 {
  object-fit: cover;
  width: 180px;
  height: 244px;
  position: absolute;
  top: 114px;
  left: 847px;
}

.vykhari-case-study .element-12 {
  object-fit: cover;
  width: 109px;
  height: 147px;
  position: absolute;
  top: 114px;
  left: 724px;
}

.vykhari-case-study .element-13 {
  object-fit: cover;
  width: 109px;
  height: 147px;
  position: absolute;
  top: 114px;
  left: 1040px;
}

.vykhari-case-study .rectangle-14 {
  background-color: #143b35;
  width: 968px;
  height: 571px;
  position: absolute;
  top: 425px;
  left: 204px;
}

.vykhari-case-study .frame-63 {
  align-items: center;
  gap: 26.78px;
  display: inline-flex;
  position: absolute;
  top: 509px;
  left: 0;
}

.vykhari-case-study .rectangle-15 {
  object-fit: cover;
  width: 230.95px;
  height: 268.61px;
  margin-bottom: -888.05px;
  margin-left: -3859.64px;
  position: relative;
}

.vykhari-case-study .rectangle-16 {
  object-fit: cover;
  width: 230.95px;
  height: 268.61px;
  margin-bottom: -888.05px;
  margin-left: -3601.91px;
  position: relative;
}

.vykhari-case-study .rectangle-17 {
  object-fit: cover;
  width: 346.43px;
  height: 403.33px;
  margin-bottom: -955.42px;
  margin-left: -3344.18px;
  position: relative;
}

.vykhari-case-study .rectangle-18 {
  object-fit: cover;
  width: 230.95px;
  height: 268.61px;
  margin-bottom: -888.05px;
  margin-left: -2970.97px;
  position: relative;
}

.vykhari-case-study .rectangle-19 {
  object-fit: cover;
  width: 230.95px;
  height: 268.61px;
  margin-bottom: -888.05px;
  margin-left: -2713.24px;
  position: relative;
}

.vykhari-case-study .text-wrapper-106 {
  color: #143b35;
  letter-spacing: 0;
  text-align: right;
  width: 178px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  line-height: 15.1px;
  position: absolute;
  top: 67px;
  left: 860px;
}

.vykhari-case-study .group-34 {
  width: 478px;
  height: 425px;
  position: absolute;
  top: 0;
  left: 700px;
}

.vykhari-case-study .overlap-21 {
  background-image: url("18-1.53f391a6.png");
  background-position: 50%;
  background-size: cover;
  width: 472px;
  height: 425px;
  position: relative;
}

.vykhari-case-study .group-35 {
  width: 84px;
  height: 15px;
  position: absolute;
  top: 356px;
  left: 361px;
}

.vykhari-case-study .text-wrapper-107 {
  color: #fff;
  letter-spacing: 0;
  width: 65px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  line-height: 15.1px;
  text-decoration: underline;
  position: absolute;
  top: 0;
  left: 0;
}

.vykhari-case-study .ellipse-19 {
  background-color: #fff;
  border-radius: 6.72px / 6.05px;
  width: 13px;
  height: 12px;
  position: absolute;
  top: 3px;
  left: 69px;
}

.vykhari-case-study .overlap-group-8 {
  width: 225px;
  height: 49px;
  position: absolute;
  top: 278px;
  left: 217px;
}

.vykhari-case-study .text-wrapper-108 {
  color: #fff;
  letter-spacing: 0;
  text-align: right;
  width: 225px;
  font-family: Charter-Bold, Helvetica;
  font-size: 26.9px;
  font-weight: 700;
  line-height: 37.8px;
  position: absolute;
  top: 11px;
  left: 0;
}

.vykhari-case-study .text-wrapper-109 {
  color: #fff;
  letter-spacing: 0;
  text-align: right;
  width: 194px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  line-height: 15.1px;
  position: absolute;
  top: 0;
  left: 31px;
}

.vykhari-case-study .text-wrapper-110 {
  color: #fff;
  letter-spacing: 0;
  text-align: right;
  width: 91px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 8.1px;
  font-weight: 400;
  line-height: 11.3px;
  position: absolute;
  top: 331px;
  left: 352px;
}

.vykhari-case-study .frame-64 {
  align-items: center;
  gap: 108.23px;
  display: inline-flex;
  position: absolute;
  top: 2247px;
  left: 42px;
}

.vykhari-case-study .frame-65 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 76.63px;
  display: inline-flex;
  position: relative;
}

.vykhari-case-study .discover-more {
  color: #143b35;
  letter-spacing: 0;
  width: 221.16px;
  font-family: Charter-Bold, Helvetica;
  font-size: 26.9px;
  font-weight: 700;
  line-height: 37.8px;
  position: relative;
}

.vykhari-case-study .text-wrapper-111 {
  color: #000;
  letter-spacing: 0;
  width: 359.64px;
  margin-top: -.67px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 16.1px;
  font-weight: 400;
  line-height: 22.7px;
  position: relative;
}

.vykhari-case-study .frame-66 {
  border: .67px solid #000;
  border-radius: 27.56px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 6.72px;
  height: 36.97px;
  padding: 13.44px 16.13px;
  display: inline-flex;
  position: relative;
  box-shadow: 4.71px 2.69px 16.13px #0003;
}

.vykhari-case-study .text-wrapper-112 {
  color: #000;
  letter-spacing: -.3px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -3.13px;
  margin-bottom: -1.79px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 12.1px;
  font-weight: 400;
  line-height: 14.5px;
  position: relative;
}

.vykhari-case-study .component-33-instance {
  width: 858px !important;
  height: 790px !important;
  position: absolute !important;
  top: 522px !important;
  left: 52px !important;
}

.vykhari-case-study .component-24 {
  width: 852px !important;
  height: 697px !important;
  top: 93px !important;
  left: 7px !important;
}

.vykhari-case-study .component-28 {
  width: 850px !important;
  height: 697px !important;
  position: absolute !important;
  top: 0 !important;
  left: 2px !important;
  overflow: hidden !important;
}

.vykhari-case-study .component-29 {
  width: 328px !important;
  height: 358px !important;
  top: 339px !important;
}

.vykhari-case-study .component-30 {
  width: 498px !important;
  height: 697px !important;
  left: 352px !important;
}

.vykhari-case-study .component-31 {
  gap: 65.21px !important;
  top: 32px !important;
}

.vykhari-case-study .component-32 {
  gap: 23.53px !important;
}

.vykhari-case-study .component-34 {
  width: 288.38px !important;
  margin-top: -.67px !important;
  font-size: 26.9px !important;
  line-height: 32.3px !important;
}

.vykhari-case-study .component-35 {
  width: 288.38px !important;
  font-size: 10.8px !important;
  line-height: 15.1px !important;
}

.vykhari-case-study .component-36 {
  width: 129.72px !important;
  height: 15px !important;
}

.vykhari-case-study .component-37 {
  width: 111px !important;
  font-size: 10.8px !important;
  line-height: 15.1px !important;
}

.vykhari-case-study .component-38 {
  border-radius: 6.05px !important;
  width: 12px !important;
  height: 12px !important;
  top: 1px !important;
  left: 116px !important;
}

.vykhari-case-study .component-39 {
  gap: 49.74px !important;
}

.vykhari-case-study .component-40 {
  border-bottom-width: .67px !important;
  gap: 6.72px !important;
  padding: 6.72px !important;
}

.vykhari-case-study .component-41 {
  margin-top: -.67px !important;
  font-size: 13.4px !important;
  line-height: 16.1px !important;
}

.vykhari-case-study .component-42 {
  gap: 6.72px !important;
  padding: 6.72px !important;
}

.vykhari-case-study .work {
  background-color: #fff;
  border: 10px solid #d3d3d3;
  border-radius: 20px;
  width: 780px;
  height: 3184px;
  position: absolute;
  top: 0;
  left: 1076px;
  overflow: hidden;
}

.vykhari-case-study .overlap-22 {
  background-color: #fff3eb;
  width: 770px;
  height: 153px;
  position: absolute;
  top: 4107px;
  left: 0;
}

.vykhari-case-study .frame-67 {
  flex-direction: column;
  align-items: center;
  gap: 13.9px;
  display: inline-flex;
  position: relative;
  top: 19px;
  left: 133px;
}

.vykhari-case-study .text-wrapper-113 {
  color: #000;
  letter-spacing: 0;
  width: 229.4px;
  margin-top: -.53px;
  font-family: Charter-Bold, Helvetica;
  font-size: 21.4px;
  font-weight: 700;
  line-height: 25.7px;
  position: relative;
}

.vykhari-case-study .frame-68 {
  flex: none;
  align-items: center;
  gap: 48.59px;
  display: inline-flex;
  position: relative;
}

.vykhari-case-study .image-3 {
  width: 137.26px;
  height: 34.82px;
  margin-bottom: -24.1px;
  margin-left: -5220.15px;
  position: relative;
}

.vykhari-case-study .image-4 {
  object-fit: cover;
  width: 61.14px;
  height: 40.09px;
  margin-bottom: -26.73px;
  margin-left: -5034.29px;
  position: relative;
}

.vykhari-case-study .image-5 {
  object-fit: cover;
  width: 78.55px;
  height: 39.28px;
  margin-bottom: -26.33px;
  margin-left: -4924.56px;
  position: relative;
}

.vykhari-case-study .image-6 {
  object-fit: cover;
  width: 80.98px;
  height: 80.98px;
  margin-bottom: -47.18px;
  margin-left: -4797.42px;
  position: relative;
}

.vykhari-case-study .overlap-23 {
  width: 775px;
  height: 543px;
  position: absolute;
  top: 0;
  left: 0;
}

.vykhari-case-study .component-1-instance {
  width: 775px !important;
  height: 543px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
}

.vykhari-case-study .component-43 {
  width: 770px !important;
  height: 543px !important;
}

.vykhari-case-study .component-44 {
  width: 770px !important;
  height: 482px !important;
  top: 49px !important;
}

.vykhari-case-study .component-45 {
  width: 264px !important;
  height: 404px !important;
  top: 83px !important;
  left: 253px !important;
}

.vykhari-case-study .component-46 {
  width: 152px !important;
  height: 229px !important;
  top: 190px !important;
  left: 529px !important;
}

.vykhari-case-study .component-47 {
  width: 75px !important;
  height: 229px !important;
  top: 190px !important;
  left: 695px !important;
}

.vykhari-case-study .component-48 {
  width: 74px !important;
  height: 229px !important;
  top: 190px !important;
}

.vykhari-case-study .component-49 {
  width: 153px !important;
  height: 229px !important;
  top: 190px !important;
  left: 87px !important;
}

.vykhari-case-study .component-50 {
  gap: 2.14px !important;
  top: 107px !important;
  left: 47px !important;
}

.vykhari-case-study .component-51 {
  width: 143.84px !important;
  margin-top: -.53px !important;
  font-size: 21.4px !important;
  line-height: 30.1px !important;
}

.vykhari-case-study .component-52 {
  width: 178.6px !important;
  font-size: 8.6px !important;
  line-height: 12px !important;
}

.vykhari-case-study .component-53 {
  width: 179px !important;
  font-size: 8.6px !important;
  line-height: 12px !important;
  top: 439px !important;
  left: 529px !important;
}

.vykhari-case-study .header-3 {
  background-color: #143b35a3;
  align-items: center;
  gap: 372.7px;
  width: 770px;
  height: 51px;
  padding: 12.83px 47.06px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.vykhari-case-study .vykhari-5 {
  width: 67.38px;
  height: 41.17px;
  margin-top: -8.02px;
  margin-bottom: -8.02px;
  position: relative;
}

.vykhari-case-study .frame-69 {
  flex: none;
  align-items: center;
  gap: 20.85px;
  margin-top: -2.14px;
  margin-bottom: -2.14px;
  display: inline-flex;
  position: relative;
}

.vykhari-case-study .frame-70 {
  align-items: center;
  width: 36.9px;
  display: flex;
  position: relative;
}

.vykhari-case-study .text-wrapper-114 {
  color: #fff;
  letter-spacing: -.09px;
  width: 52.4px;
  margin-top: -.53px;
  margin-right: -15.51px;
  font-family: Proxima Nova-Light, Helvetica;
  font-size: 8.6px;
  font-weight: 300;
  line-height: 12.5px;
  position: relative;
}

.vykhari-case-study .frame-71 {
  align-items: center;
  width: 35.83px;
  display: flex;
  position: relative;
}

.vykhari-case-study .text-wrapper-115 {
  color: #fff;
  letter-spacing: -.09px;
  width: 47.06px;
  margin-top: -.53px;
  margin-right: -11.23px;
  font-family: Proxima Nova-Light, Helvetica;
  font-size: 8.6px;
  font-weight: 300;
  line-height: 12.5px;
  position: relative;
}

.vykhari-case-study .frame-72 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 5.35px;
  height: 15.51px;
  padding: 5.35px;
  display: inline-flex;
  position: relative;
}

.vykhari-case-study .text-wrapper-116 {
  color: #fff;
  letter-spacing: -.09px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -4.13px;
  margin-bottom: -3.06px;
  font-family: Proxima Nova-Light, Helvetica;
  font-size: 8.6px;
  font-weight: 300;
  line-height: 12.5px;
  position: relative;
}

.vykhari-case-study .frame-73 {
  border: .53px solid #fff;
  border-radius: 21.92px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 5.35px;
  height: 29.41px;
  padding: 10.69px 12.83px;
  display: inline-flex;
  position: relative;
  box-shadow: 3.74px 2.14px 12.83px #0003;
}

.vykhari-case-study .text-wrapper-117 {
  color: #fff;
  letter-spacing: -.24px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -2.52px;
  margin-bottom: -1.45px;
  font-family: Proxima Nova-Light, Helvetica;
  font-size: 9.6px;
  font-weight: 300;
  line-height: 11.6px;
  position: relative;
}

.vykhari-case-study .text-wrapper-118 {
  color: #303030;
  letter-spacing: -.32px;
  width: 199px;
  font-family: Livvic, Helvetica;
  font-size: 21.4px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 1417px;
  left: -494px;
}

.vykhari-case-study .group-36 {
  width: 61px;
  height: 12px;
  position: absolute;
  top: 4069px;
  left: 663px;
}

.vykhari-case-study .text-wrapper-119 {
  color: #000;
  letter-spacing: 0;
  width: 47px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 8.6px;
  font-weight: 400;
  line-height: 12px;
  text-decoration: underline;
  position: absolute;
  top: 0;
  left: 0;
}

.vykhari-case-study .ellipse-20 {
  background-color: #000;
  border-radius: 4.81px;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 2px;
  left: 49px;
}

.vykhari-case-study .footers-3 {
  background-color: #143b35;
  width: 770px;
  height: 206px;
  position: absolute;
  top: 4260px;
  left: 0;
  overflow: hidden;
}

.vykhari-case-study .vykhari-6 {
  width: 67px;
  height: 41px;
  position: absolute;
  top: -10px;
  left: -5023px;
}

.vykhari-case-study .company-links-3 {
  width: 66px;
  height: 109px;
  position: absolute;
  top: 37px;
  left: 390px;
}

.vykhari-case-study .text-wrapper-120 {
  color: var(--white);
  letter-spacing: 0;
  font-family: Proxima Nova-Bold, Helvetica;
  font-size: 9.6px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.vykhari-case-study .text-wrapper-121 {
  color: var(--primary-light);
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 8.6px;
  font-weight: 400;
  line-height: 12.8px;
  position: absolute;
  top: 25px;
  left: 0;
}

.vykhari-case-study .text-wrapper-122 {
  color: var(--primary-light);
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 8.6px;
  font-weight: 400;
  line-height: 12.8px;
  position: absolute;
  top: 48px;
  left: 0;
}

.vykhari-case-study .text-wrapper-123 {
  color: var(--primary-light);
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 7.5px;
  font-weight: 400;
  line-height: 11.2px;
  position: absolute;
  top: 186px;
  left: 335px;
}

.vykhari-case-study .resources-links-3 {
  width: 69px;
  height: 109px;
  position: absolute;
  top: 37px;
  left: 499px;
}

.vykhari-case-study .product-links-3 {
  width: 60px;
  height: 109px;
  position: absolute;
  top: 37px;
  left: 608px;
}

.vykhari-case-study .divider-3 {
  width: 770px;
  height: 1px;
  position: absolute;
  top: 131px;
  left: -5087px;
}

.vykhari-case-study .frame-74 {
  width: 676px;
  height: 3454px;
  position: absolute;
  top: 590px;
  left: 47px;
  overflow: hidden;
}

.vykhari-case-study .frame-75 {
  justify-content: center;
  align-items: flex-end;
  gap: 293.56px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.vykhari-case-study .frame-76 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 11.76px;
  display: inline-flex;
  position: relative;
}

.vykhari-case-study .frame-77 {
  border: .53px solid #000;
  border-radius: 4.28px;
  flex: none;
  align-items: flex-start;
  gap: 5.35px;
  padding: 5.35px;
  display: inline-flex;
  position: relative;
}

.vykhari-case-study .text-wrapper-124 {
  color: #143b35;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.53px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 8.6px;
  font-weight: 400;
  line-height: 12px;
  position: relative;
}

.vykhari-case-study .frame-78 {
  flex-direction: column;
  align-items: flex-start;
  gap: 2.14px;
  width: 172.18px;
  height: 27.27px;
  display: flex;
  position: relative;
}

.vykhari-case-study .text-wrapper-125 {
  color: #143b35;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.53px;
  font-family: Charter-Bold, Helvetica;
  font-size: 19.2px;
  font-weight: 700;
  line-height: 27px;
  position: relative;
}

.vykhari-case-study .text-wrapper-126 {
  color: #143b35;
  letter-spacing: 0;
  text-align: right;
  width: 210.15px;
  font-family: Proxima Nova-Regular, Helvetica;
  font-size: 10.7px;
  font-weight: 400;
  line-height: 15px;
  position: relative;
}

.vykhari-case-study .frame-79 {
  align-items: flex-start;
  gap: 10.16px;
  height: 302px;
  display: inline-flex;
  position: absolute;
  top: 335px;
  left: 0;
}

.vykhari-case-study .rectangle-20 {
  object-fit: cover;
  width: 218.17px;
  height: 301.58px;
  position: relative;
}

.vykhari-case-study .rectangle-21 {
  object-fit: cover;
  width: 217.63px;
  height: 301.58px;
  position: relative;
}

.vykhari-case-study .rectangle-22 {
  object-fit: cover;
  width: 220px;
  height: 235px;
  position: absolute;
  top: 89px;
  left: 456px;
}

.vykhari-case-study .rectangle-23 {
  object-fit: cover;
  width: 446px;
  height: 236px;
  position: absolute;
  top: 88px;
  left: 0;
}

.vykhari-case-study .frame-80 {
  justify-content: center;
  align-items: flex-end;
  gap: 293.56px;
  display: inline-flex;
  position: absolute;
  top: 674px;
  left: 0;
}

.vykhari-case-study .frame-81 {
  align-items: flex-start;
  gap: 10.16px;
  height: 302px;
  display: inline-flex;
  position: absolute;
  top: 1010px;
  left: 0;
}

.vykhari-case-study .rectangle-24 {
  object-fit: cover;
  width: 220px;
  height: 235px;
  position: absolute;
  top: 764px;
  left: 456px;
}

.vykhari-case-study .rectangle-25 {
  object-fit: cover;
  width: 446px;
  height: 236px;
  position: absolute;
  top: 762px;
  left: 0;
}

.vykhari-case-study .frame-82 {
  justify-content: center;
  align-items: flex-end;
  gap: 293.56px;
  display: inline-flex;
  position: absolute;
  top: 1350px;
  left: 0;
}

.vykhari-case-study .frame-83 {
  align-items: flex-start;
  gap: 10.16px;
  height: 302px;
  display: inline-flex;
  position: absolute;
  top: 1685px;
  left: 0;
}

.vykhari-case-study .rectangle-26 {
  object-fit: cover;
  width: 220px;
  height: 235px;
  position: absolute;
  top: 1439px;
  left: 456px;
}

.vykhari-case-study .rectangle-27 {
  object-fit: cover;
  width: 446px;
  height: 236px;
  position: absolute;
  top: 1437px;
  left: 0;
}

.vykhari-case-study .frame-84 {
  justify-content: center;
  align-items: flex-end;
  gap: 293.56px;
  display: inline-flex;
  position: absolute;
  top: 2025px;
  left: 0;
}

.vykhari-case-study .frame-85 {
  align-items: flex-start;
  gap: 10.16px;
  height: 302px;
  display: inline-flex;
  position: absolute;
  top: 2419px;
  left: 0;
}

.vykhari-case-study .rectangle-28 {
  object-fit: cover;
  width: 218.17px;
  height: 165.65px;
  position: relative;
}

.vykhari-case-study .rectangle-29 {
  object-fit: cover;
  width: 217.63px;
  height: 165.65px;
  position: relative;
}

.vykhari-case-study .MG-copy-4 {
  width: 676px;
  height: 295px;
  position: absolute;
  top: 2113px;
  left: 0;
}

.vykhari-case-study .frame-86 {
  justify-content: center;
  align-items: flex-end;
  gap: 293.56px;
  display: inline-flex;
  position: absolute;
  top: 2759px;
  left: 0;
}

.vykhari-case-study .frame-87 {
  align-items: flex-start;
  gap: 10.16px;
  height: 302px;
  display: inline-flex;
  position: absolute;
  top: 3152px;
  left: 0;
}

.vykhari-case-study .rectangle-30 {
  object-fit: cover;
  width: 218.17px;
  height: 301.58px;
  margin-bottom: -471.01px;
  margin-left: -5134.06px;
  position: relative;
}

.vykhari-case-study .rectangle-31 {
  object-fit: cover;
  width: 217.63px;
  height: 301.58px;
  margin-bottom: -471.01px;
  margin-left: -4905.73px;
  position: relative;
}

.vykhari-case-study .rectangle-32 {
  object-fit: cover;
  width: 218.17px;
  height: 301.58px;
  margin-bottom: -471.01px;
  margin-left: -4677.94px;
  position: relative;
}

.vykhari-case-study .MG-copy-5 {
  width: 344px;
  height: 295px;
  position: absolute;
  top: 6470px;
  left: -5134px;
}

.vykhari-case-study .MG-copy-6 {
  width: 321px;
  height: 295px;
  position: absolute;
  top: 6470px;
  left: -4781px;
}

.vykhari-case-study .frame-88 {
  justify-content: center;
  align-items: flex-end;
  gap: 293.56px;
  display: inline-flex;
  position: absolute;
  top: 3491px;
  left: 0;
}

.vykhari-case-study .frame-89 {
  align-items: flex-start;
  gap: 10.16px;
  height: 302px;
  display: inline-flex;
  position: absolute;
  top: 3885px;
  left: 0;
}

.vykhari-case-study .rectangle-33 {
  object-fit: cover;
  width: 218.17px;
  height: 301.58px;
  margin-top: -261.56px;
  margin-left: -5134.06px;
  position: relative;
}

.vykhari-case-study .rectangle-34 {
  object-fit: cover;
  width: 217.63px;
  height: 301.58px;
  margin-top: -261.56px;
  margin-left: -4905.73px;
  position: relative;
}

.vykhari-case-study .rectangle-35 {
  object-fit: cover;
  width: 218.17px;
  height: 301.58px;
  margin-top: -261.56px;
  margin-left: -4677.94px;
  position: relative;
}

.vykhari-case-study .MG-copy-7 {
  object-fit: cover;
  width: 344px;
  height: 295px;
  position: absolute;
  top: 7202px;
  left: -5134px;
}

.vykhari-case-study .MG-copy-8 {
  object-fit: cover;
  width: 321px;
  height: 295px;
  position: absolute;
  top: 7202px;
  left: -4781px;
}

.vykhari-case-study .frame-90 {
  justify-content: center;
  align-items: flex-end;
  gap: 293.56px;
  display: inline-flex;
  position: absolute;
  top: 4224px;
  left: 6px;
}

.vykhari-case-study .frame-91 {
  align-items: flex-start;
  gap: 10.16px;
  height: 302px;
  display: inline-flex;
  position: absolute;
  top: 4617px;
  left: 6px;
}

.vykhari-case-study .rectangle-36 {
  object-fit: cover;
  width: 218.17px;
  height: 301.58px;
  margin-top: -994.13px;
  margin-left: -5140.47px;
  position: relative;
}

.vykhari-case-study .rectangle-37 {
  object-fit: cover;
  width: 217.63px;
  height: 301.58px;
  margin-top: -994.13px;
  margin-left: -4912.15px;
  position: relative;
}

.vykhari-case-study .rectangle-38 {
  width: 218.17px;
  height: 301.58px;
  margin-top: -994.13px;
  margin-left: -4684.35px;
  position: relative;
}

.vykhari-case-study .MG-copy-9 {
  object-fit: cover;
  width: 446px;
  height: 295px;
  position: absolute;
  top: 7935px;
  left: -5128px;
}

.vykhari-case-study .MG-copy-10 {
  object-fit: cover;
  width: 219px;
  height: 295px;
  position: absolute;
  top: 7935px;
  left: -4672px;
}

.vykhari-case-study .element-14 {
  object-fit: cover;
  width: 1920px;
  height: 1578px;
  position: absolute;
  top: 3156px;
  left: 0;
}

.vykhari-case-study .mask-group-wrapper {
  background-image: url("rectangle-122.f9619642.svg");
  background-size: 100% 100%;
  width: 1920px;
  height: 540px;
  position: absolute;
  top: 16484px;
  left: 0;
}

.vykhari-case-study .mask-group-2 {
  width: 1920px;
  height: 540px;
  position: absolute;
  top: 0;
  left: 0;
}

.vykhari-case-study .frame-92 {
  border-radius: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 56px;
  width: 1919px;
  padding: 100px 120px;
  display: flex;
  position: absolute;
  top: 15993px;
  left: 0;
}

.vykhari-case-study .element-delivered {
  color: #000;
  letter-spacing: -.8px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 40px;
  font-weight: 400;
  line-height: 56px;
  position: relative;
}

.vykhari-case-study .frame-93 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.vykhari-case-study .frame-94 {
  flex: none;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.vykhari-case-study .frame-95 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.vykhari-case-study .text-wrapper-127 {
  color: #000;
  letter-spacing: -.56px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 28px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.vykhari-case-study .text-wrapper-128 {
  color: #979797;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.vykhari-case-study .text-wrapper-129 {
  color: #0d99ff;
  letter-spacing: -.48px;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  text-decoration: underline;
  position: relative;
}

/*# sourceMappingURL=index.5869f967.css.map */
