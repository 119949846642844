.property-component-wrapper {
  height: 669px;
  width: 797px;
}

.property-component-wrapper .rectangle {
  height: 669px;
  left: 0;
  position: absolute;
  top: 0;
  width: 797px;
}

.property-component-wrapper .overlap-group {
  height: 710px;
  left: -93px;
  position: relative;
  width: 983px;
}

.property-component-wrapper .rectangle-wrapper {
  background-color: #0000009e;
  background-image: url(../../../static/img/component-27-1.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 669px;
  left: 93px;
  position: absolute;
  top: 0;
  width: 797px;
}

.property-component-wrapper .div {
  height: 710px;
  left: 0;
  position: absolute;
  top: 0;
  width: 983px;
}

.property-component-wrapper .group {
  height: 307px;
  left: 172px;
  position: absolute;
  top: 64px;
  width: 335px;
}

.property-component-wrapper .group-2 {
  height: 307px;
  position: relative;
  width: 337px;
}

.property-component-wrapper .frame {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 4px;
  left: 2px;
  position: absolute;
  top: 115px;
}

.property-component-wrapper .text-wrapper {
  color: #ffffff;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 22.5px;
  margin-top: -1px;
  position: relative;
  width: 333px;
}

.property-component-wrapper .text-wrapper-2 {
  color: #ffffff;
  font-family: "Charter-Roman", Helvetica;
  font-size: 32px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.property-component-wrapper .vector {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 74px;
  width: 320px;
}

.property-component-wrapper .group-3 {
  height: 22px;
  left: 0;
  position: absolute;
  top: 285px;
  width: 108px;
}

.property-component-wrapper .text-wrapper-3 {
  color: #ffffff;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 22.5px;
  position: absolute;
  text-decoration: underline;
  top: 0;
  width: 87px;
}

.property-component-wrapper .ellipse {
  background-color: #ffffff;
  border-radius: 9px;
  height: 18px;
  left: 88px;
  position: absolute;
  top: 4px;
  width: 18px;
}

.property-component-wrapper.property-1-component-25 {
  background-color: #0000009e;
  background-image: url(../../../static/img/property-1-component-25.png);
  background-position: 50% 50%;
  background-size: cover;
  position: relative;
}

.property-component-wrapper.property-1-component-26 {
  overflow: hidden;
}
