.component-33 {
  height: 1175px;
  position: relative;
  width: 1277px;
}

.component-33 .overlap-group-2 {
  height: 1037px;
  left: 10px;
  position: absolute;
  top: 138px;
  width: 1267px;
}

.component-33 .component-2 {
  height: 1037px;
  left: 3px;
  position: absolute;
  top: 0;
  width: 1264px;
}

.component-33 .DSC-copy-3 {
  height: 1037px;
  left: 523px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 741px;
}

.component-33 .DSC-copy-4 {
  height: 533px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 504px;
  width: 488px;
}

.component-33 .frame-2 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 97px;
  left: 0;
  position: absolute;
  top: 47px;
}

.component-33 .frame-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 35px;
  position: relative;
}

.component-33 .editorial-styling {
  color: #000000;
  font-family: "Charter-Bold", Helvetica;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 48px;
  margin-top: -1px;
  position: relative;
  width: 429px;
}

.component-33 .editorialist-fuses {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 22.5px;
  position: relative;
  width: 429px;
}

.component-33 .group-8 {
  height: 22px;
  position: relative;
  width: 192px;
}

.component-33 .text-wrapper-15 {
  color: #000000;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 22.5px;
  position: absolute;
  text-decoration: underline;
  top: 0;
  width: 165px;
}

.component-33 .ellipse-3 {
  background-color: #000000;
  border-radius: 9px;
  height: 18px;
  left: 172px;
  position: absolute;
  top: 2px;
  width: 18px;
}

.component-33 .frame-4 {
  align-items: flex-start;
  display: inline-flex;
  gap: 74px;
  left: 0;
  position: absolute;
  top: 0;
}

.component-33 .frame-5 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 10px;
  position: relative;
}

.component-33 .text-wrapper-16 {
  font-family: "Charter-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.component-33 .frame-6 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 10px;
  position: relative;
}

.component-33 .text-wrapper-17 {
  font-family: "Charter-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.component-33 .frame-7 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 10px;
  position: relative;
}

.component-33 .text-wrapper-18 {
  font-family: "Charter-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.component-33 .frame-8 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 10px;
  position: relative;
}

.component-33 .text-wrapper-19 {
  font-family: "Charter-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.component-33 .frame-9 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 10px;
  position: relative;
}

.component-33 .text-wrapper-20 {
  font-family: "Charter-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.component-33 .frame-163026 {
  font-family: "Proxima Nova-Regular", Helvetica;
}

.component-33 .frame-163023 {
  font-family: "Proxima Nova-Regular", Helvetica;
}

.component-33 .frame-163024 {
  font-family: "Proxima Nova-Regular", Helvetica;
}

.component-33 .frame-163022 {
  font-family: "Proxima Nova-Regular", Helvetica;
}

.component-33 .frame-163025 {
  font-family: "Charter-Roman", Helvetica;
}

.component-33 .property-1-frame-163022 {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #143b35;
}

.component-33 .property-1-frame-163023 {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #143b35;
}

.component-33 .property-1-frame-163024 {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #143b35;
}

.component-33 .property-1-frame-163025 {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #143b35;
}

.component-33 .property-1-frame-163026 {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #143b35;
}

.component-33 .frame-5.property-1-frame-163026 .text-wrapper-16 {
  color: #888888;
}

.component-33 .frame-5.property-1-frame-163023 .text-wrapper-16 {
  color: #888888;
}

.component-33 .frame-5.property-1-frame-163024 .text-wrapper-16 {
  color: #888888;
}

.component-33 .frame-5.property-1-frame-163022 .text-wrapper-16 {
  color: #143b35;
}

.component-33 .frame-5.property-1-frame-163025 .text-wrapper-16 {
  color: #888888;
}

.component-33 .frame-6.property-1-frame-163026 .text-wrapper-17 {
  color: #888888;
}

.component-33 .frame-6.property-1-frame-163023 .text-wrapper-17 {
  color: #143b35;
}

.component-33 .frame-6.property-1-frame-163024 .text-wrapper-17 {
  color: #888888;
}

.component-33 .frame-6.property-1-frame-163022 .text-wrapper-17 {
  color: #888888;
}

.component-33 .frame-6.property-1-frame-163025 .text-wrapper-17 {
  color: #888888;
}

.component-33 .frame-7.property-1-frame-163026 .text-wrapper-18 {
  color: #888888;
}

.component-33 .frame-7.property-1-frame-163023 .text-wrapper-18 {
  color: #888888;
}

.component-33 .frame-7.property-1-frame-163024 .text-wrapper-18 {
  color: #143b35;
}

.component-33 .frame-7.property-1-frame-163022 .text-wrapper-18 {
  color: #888888;
}

.component-33 .frame-7.property-1-frame-163025 .text-wrapper-18 {
  color: #888888;
}

.component-33 .frame-8.property-1-frame-163026 .text-wrapper-19 {
  color: #888888;
}

.component-33 .frame-8.property-1-frame-163023 .text-wrapper-19 {
  color: #888888;
}

.component-33 .frame-8.property-1-frame-163024 .text-wrapper-19 {
  color: #888888;
}

.component-33 .frame-8.property-1-frame-163022 .text-wrapper-19 {
  color: #888888;
}

.component-33 .frame-8.property-1-frame-163025 .text-wrapper-19 {
  color: #143b35;
}

.component-33 .frame-9.property-1-frame-163026 .text-wrapper-20 {
  color: #143b35;
}

.component-33 .frame-9.property-1-frame-163023 .text-wrapper-20 {
  color: #888888;
}

.component-33 .frame-9.property-1-frame-163024 .text-wrapper-20 {
  color: #888888;
}

.component-33 .frame-9.property-1-frame-163022 .text-wrapper-20 {
  color: #888888;
}

.component-33 .frame-9.property-1-frame-163025 .text-wrapper-20 {
  color: #888888;
}
