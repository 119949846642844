.vykhari-case-study {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

body{
  overflow-x: hidden;
}

.vykhari-case-study .div-2 {
  background-color: #ffffff;
  height: 17024px;
/*   overflow: hidden; */
  position: relative;
  width: 1920px;
}

.vykhari-case-study .overlap {
  height: 9059px;
  left: -13px;
  position: absolute;
  top: 969px;
  width: 1933px;
}

.vykhari-case-study .rectangle-3 {
  background-color: #f3fffd;
  height: 7359px;
  left: 13px;
  position: absolute;
  top: 473px;
  width: 1920px;
}

.vykhari-case-study .the-challenge {
  align-items: center;
  background-color: #fff5e2;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  left: 13px;
  padding: 80px 120px;
  position: absolute;
  top: 0;
  width: 1920px;
}

.vykhari-case-study .frame-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  position: relative;
  width: 100%;
}

.vykhari-case-study .frame-11 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.vykhari-case-study .text-wrapper-22 {
  align-self: stretch;
  color: #000000;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 40px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 56px;
  margin-top: -1px;
  position: relative;
}

.vykhari-case-study .frame-12 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.vykhari-case-study .in-a-world-where {
  color: #000000;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 44.8px;
  margin-top: -1px;
  position: relative;
  width: 657px;
}

.vykhari-case-study .text-wrapper-23 {
  color: #000000;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 44.8px;
  margin-top: -1px;
  position: relative;
  width: 631px;
}

.vykhari-case-study .final {
  background-color: #ffffff;
  border: 16px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgb(161.5, 161.5, 161.5), rgba(161.5, 161.5, 161.5, 0)) 1;
  border-radius: 12px;
  height: 7192px;
  left: 224px;
  overflow: hidden;
  position: absolute;
  top: 622px;
  width: 1472px;
}

.vykhari-case-study .overlap-2 {
  height: 969px;
  left: 0;
  position: absolute;
  top: 1010px;
  width: 1469px;
}

.vykhari-case-study .rectangle-4 {
  background-color: #ffebdd;
  height: 969px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1441px;
}

.vykhari-case-study .component-instance {
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
}

.vykhari-case-study .component-27-instance {
  background-image: url(../../../static/img/component-27.png) !important;
  height: 592px !important;
  left: 644px !important;
  position: absolute !important;
  top: 0 !important;
}

.vykhari-case-study .design-component-instance-node {
  height: 592px !important;
  width: 796px !important;
}

.vykhari-case-study .rectangle-5 {
  height: 370px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 599px;
  width: 644px;
}

.vykhari-case-study .rectangle-6 {
  background-color: #fff5e2;
  height: 377px;
  left: 644px;
  position: absolute;
  top: 592px;
  width: 396px;
}

.vykhari-case-study .xa {
  height: 247px;
  left: 644px;
  position: absolute;
  top: 592px;
  width: 396px;
}

.vykhari-case-study .rectangle-7 {
  background-color: #143b35;
  height: 377px;
  left: 1040px;
  position: absolute;
  top: 592px;
  width: 429px;
}

.vykhari-case-study .frame-13 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 4px;
  left: 671px;
  position: absolute;
  top: 792px;
}

.vykhari-case-study .text-wrapper-24 {
  color: #ffffff;
  font-family: "Charter-Roman", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.vykhari-case-study .text-wrapper-25 {
  color: #143b35;
  font-family: "Charter-Roman", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 671px;
  letter-spacing: 0;
  line-height: 22.5px;
  position: absolute;
  top: 861px;
  width: 349px;
}

.vykhari-case-study .frame-14 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 13px;
  left: 71px;
  position: absolute;
  top: 697px;
}

.vykhari-case-study .frame-15 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
}

.vykhari-case-study .text-wrapper-26 {
  color: #ffffff;
  font-family: "Charter-Roman", Helvetica;
  font-size: 36px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.vykhari-case-study .text-wrapper-27 {
  color: #ffffff;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 22.5px;
  position: relative;
  width: 365px;
}

.vykhari-case-study .group-9 {
  height: 22px;
  position: relative;
  width: 107px;
}

.vykhari-case-study .text-wrapper-28 {
  color: #ffffff;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 22.5px;
  position: absolute;
  text-decoration: underline;
  top: 0;
  width: 87px;
}

.vykhari-case-study .ellipse-4 {
  background-color: #ffffff;
  border-radius: 9px;
  height: 18px;
  left: 87px;
  position: absolute;
  top: 4px;
  width: 18px;
}

.vykhari-case-study .group-10 {
  height: 22px;
  left: 671px;
  position: absolute;
  top: 923px;
  width: 112px;
}

.vykhari-case-study .text-wrapper-29 {
  color: #143b35;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 22.5px;
  position: absolute;
  text-decoration: underline;
  top: 0;
  width: 87px;
}

.vykhari-case-study .ellipse-5 {
  background-color: #143b35;
  border-radius: 9px;
  height: 18px;
  left: 92px;
  position: absolute;
  top: 4px;
  width: 18px;
}

.vykhari-case-study .frame-16 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 45px;
  left: 1093px;
  position: absolute;
  top: 674px;
  width: 309px;
}

.vykhari-case-study .frame-17 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 295px;
}

.vykhari-case-study .text-wrapper-30 {
  color: #ffffff;
  font-family: "Charter-Roman", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 273px;
}

.vykhari-case-study .text-wrapper-31 {
  color: #ffffff;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 22.5px;
  position: relative;
  width: 295px;
}

.vykhari-case-study .group-11 {
  height: 22px;
  position: relative;
  width: 108px;
}

.vykhari-case-study .ellipse-6 {
  background-color: #ffffff;
  border-radius: 9px;
  height: 18px;
  left: 88px;
  position: absolute;
  top: 4px;
  width: 18px;
}

.vykhari-case-study .frame-18 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 6px;
  left: 58px;
  position: absolute;
  top: 95px;
}

.vykhari-case-study .commercial-styling-e {
  color: #ffffff;
  font-family: "Charter-Roman", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 424px;
}

.vykhari-case-study .text-wrapper-32 {
  color: #303030;
  font-family: "Livvic", Helvetica;
  font-size: 40px;
  font-weight: 600;
  left: -924px;
  letter-spacing: -0.6px;
  line-height: normal;
  position: absolute;
  top: 2659px;
  width: 373px;
}

.vykhari-case-study .overlap-3 {
  background-color: #143b35;
  height: 800px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1441px;
}

.vykhari-case-study .component-22 {
  background-image: url(../../../static/img/rectangle-383.png) !important;
  height: 392px !important;
  left: 190px !important;
  position: absolute !important;
  top: 100px !important;
  width: 664px !important;
}

.vykhari-case-study .rectangle-8 {
  height: 700px;
  left: 889px;
  object-fit: cover;
  position: absolute;
  top: 100px;
  width: 549px;
}

.vykhari-case-study .overlap-4 {
  height: 224px;
  left: 88px;
  position: absolute;
  top: 534px;
  width: 738px;
}

.vykhari-case-study .group-12 {
  height: 224px;
  left: 3px;
  position: absolute;
  top: 0;
  width: 735px;
}

.vykhari-case-study .group-13 {
  height: 175px;
  left: 0;
  position: absolute;
  top: 0;
  width: 737px;
}

.vykhari-case-study .text-wrapper-33 {
  color: #ffffff;
  font-family: "New York-Regular", Helvetica;
  font-size: 52px;
  font-weight: 400;
  height: 62px;
  left: 0;
  letter-spacing: 1.04px;
  line-height: 62.4px;
  position: absolute;
  top: 0;
  width: 319px;
}

.vykhari-case-study .frame-19 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 23px;
  justify-content: center;
  left: 201px;
  position: absolute;
  top: 108px;
  width: 534px;
}

.vykhari-case-study .vector-2 {
  height: 1px;
  margin-top: -0.5px;
  object-fit: cover;
  position: relative;
  width: 533px;
}

.vykhari-case-study .frame-20 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 173px;
  position: relative;
  width: 180px;
}

.vykhari-case-study .text-wrapper-34 {
  color: #ffffff;
  font-family: "Charter-Roman", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 22.5px;
  margin-top: -1px;
  position: relative;
  text-align: right;
  width: 176px;
}

.vykhari-case-study .component-25 {
  left: 205px !important;
  position: absolute !important;
  top: 202px !important;
}

.vykhari-case-study .text-wrapper-35 {
  color: #ffffff;
  font-family: "Charter-Roman", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 44px;
  left: 0;
  letter-spacing: 0;
  line-height: 22.5px;
  position: absolute;
  top: 70px;
  width: 176px;
}

.vykhari-case-study .header {
  align-items: center;
  background-color: #143b35a3;
  display: flex;
  gap: 697px;
  height: 95px;
  left: 0;
  padding: 24px 88px;
  position: absolute;
  top: 0;
  width: 1440px;
}

.vykhari-case-study .vykhari {
  height: 77px;
  margin-bottom: -15px;
  margin-top: -15px;
  position: relative;
  width: 126px;
}

.vykhari-case-study .frame-21 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 39px;
  margin-bottom: -4px;
  margin-top: -4px;
  position: relative;
}

.vykhari-case-study .frame-22 {
  align-items: center;
  display: flex;
  position: relative;
  width: 69px;
}

.vykhari-case-study .text-wrapper-36 {
  color: #ffffff;
  font-family: "Proxima Nova-Light", Helvetica;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.16px;
  line-height: 23.4px;
  margin-right: -29px;
  margin-top: -1px;
  position: relative;
  width: 98px;
}

.vykhari-case-study .frame-23 {
  align-items: center;
  display: flex;
  position: relative;
  width: 67px;
}

.vykhari-case-study .text-wrapper-37 {
  color: #ffffff;
  font-family: "Proxima Nova-Light", Helvetica;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.16px;
  line-height: 23.4px;
  margin-right: -21px;
  margin-top: -1px;
  position: relative;
  width: 88px;
}

.vykhari-case-study .frame-24 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  height: 29px;
  justify-content: center;
  padding: 10px;
  position: relative;
}

.vykhari-case-study .text-wrapper-38 {
  color: #ffffff;
  font-family: "Proxima Nova-Light", Helvetica;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.16px;
  line-height: 23.4px;
  margin-bottom: -6px;
  margin-top: -8px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vykhari-case-study .frame-25 {
  align-items: center;
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 41px;
  box-shadow: 7px 4px 24px #00000033;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  height: 55px;
  justify-content: center;
  padding: 20px 24px;
  position: relative;
}

.vykhari-case-study .text-wrapper-39 {
  color: #ffffff;
  font-family: "Proxima Nova-Light", Helvetica;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -0.45px;
  line-height: 21.6px;
  margin-bottom: -2.5px;
  margin-top: -4.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vykhari-case-study .group-14 {
  height: 692px;
  left: 89px;
  position: absolute;
  top: 2190px;
  width: 1262px;
}

.vykhari-case-study .frame-26 {
  height: 692px;
  position: relative;
}

.vykhari-case-study .overlap-5 {
  height: 692px;
  left: 0;
  position: absolute;
  top: 0;
  width: 972px;
}

.vykhari-case-study .rectangle-9 {
  height: 691px;
  left: 689px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 283px;
}

.vykhari-case-study .xa-2 {
  height: 692px;
  left: 0;
  position: absolute;
  top: 0;
  width: 690px;
}

.vykhari-case-study .text-wrapper-40 {
  color: #ffffff;
  font-family: "Charter-Bold", Helvetica;
  font-size: 72px;
  font-weight: 700;
  height: 86px;
  left: 540px;
  letter-spacing: -0.72px;
  line-height: 86.4px;
  position: absolute;
  text-align: right;
  top: 522px;
  white-space: nowrap;
}

.vykhari-case-study .text-wrapper-41 {
  color: #ffffff;
  font-family: "Charter-Bold", Helvetica;
  font-size: 52px;
  font-weight: 700;
  height: 62px;
  left: 853px;
  letter-spacing: -0.52px;
  line-height: 62.4px;
  position: absolute;
  text-align: right;
  top: 534px;
  white-space: nowrap;
}

.vykhari-case-study .text-wrapper-42 {
  color: #ffffff;
  font-family: "Charter-Roman", Helvetica;
  font-size: 32px;
  font-weight: 400;
  height: 38px;
  left: 402px;
  letter-spacing: -0.32px;
  line-height: 38.4px;
  position: absolute;
  text-align: center;
  top: 618px;
  white-space: nowrap;
}

.vykhari-case-study .line {
  height: 499px;
  left: 630px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1px;
}

.vykhari-case-study .overlap-group-4 {
  background-image: url(../../../static/img/rectangle-367.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 691px;
  left: 972px;
  position: absolute;
  top: 0;
  width: 290px;
}

.vykhari-case-study .text-wrapper-43 {
  color: #ffffff;
  font-family: "Charter-Bold", Helvetica;
  font-size: 52px;
  font-weight: 700;
  height: 62px;
  left: 169px;
  letter-spacing: -0.52px;
  line-height: 62.4px;
  position: absolute;
  text-align: right;
  top: 534px;
  white-space: nowrap;
}

.vykhari-case-study .frame-27 {
  align-items: center;
  display: inline-flex;
  gap: 819px;
  left: 89px;
  position: absolute;
  top: 2058px;
}

.vykhari-case-study .text-wrapper-44 {
  color: #000000;
  font-family: "Charter-Roman", Helvetica;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: -0.4px;
  line-height: 48px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.vykhari-case-study .frame-28 {
  align-items: center;
  border: 1px solid;
  border-color: #143b35;
  border-radius: 41px;
  box-shadow: 7px 4px 24px #00000033;
  display: flex;
  gap: 10px;
  height: 69px;
  justify-content: center;
  padding: 20px 24px;
  position: relative;
  width: 197px;
}

.vykhari-case-study .text-wrapper-45 {
  color: #143b35;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.5px;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vykhari-case-study .overlap-6 {
  background-image: url(../../../static/img/rectangle-361.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 706px;
  left: 0;
  position: absolute;
  top: 3897px;
  width: 1440px;
}

.vykhari-case-study .text-wrapper-46 {
  color: #ffffff;
  font-family: "Charter-Roman", Helvetica;
  font-size: 64px;
  font-weight: 400;
  height: 159px;
  left: 85px;
  letter-spacing: -0.64px;
  line-height: 76.8px;
  position: absolute;
  top: 295px;
  width: 593px;
}

.vykhari-case-study .frame-29 {
  align-items: center;
  display: inline-flex;
  gap: 733px;
  left: 88px;
  position: absolute;
  top: 4673px;
}

.vykhari-case-study .text-wrapper-47 {
  color: #000000;
  font-family: "Charter-Roman", Helvetica;
  font-size: 56px;
  font-weight: 400;
  letter-spacing: -0.56px;
  line-height: 67.2px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.vykhari-case-study .overlap-7 {
  height: 540px;
  left: 0;
  position: absolute;
  top: 6037px;
  width: 1440px;
}

.vykhari-case-study .IMG-5 {
  height: 496px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1440px;
}

.vykhari-case-study .DSC-wrapper {
  background-color: #927e6c;
  height: 355px;
  left: 472px;
  position: absolute;
  top: 69px;
  width: 239px;
}

.vykhari-case-study .DSC {
  height: 343px;
  left: 5px;
  object-fit: cover;
  position: absolute;
  top: 5px;
  width: 228px;
}

.vykhari-case-study .image {
  height: 233px;
  left: 0;
  position: absolute;
  top: 307px;
  width: 1440px;
}

.vykhari-case-study .img-wrapper {
  background-color: #927e6c;
  height: 355px;
  left: 729px;
  position: absolute;
  top: 69px;
  width: 239px;
}

.vykhari-case-study .experience-the-power {
  color: #ffffff;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 52px;
  font-weight: 400;
  height: 123px;
  left: 394px;
  letter-spacing: -1.3px;
  line-height: 52px;
  position: absolute;
  text-align: center;
  top: 150px;
  width: 609px;
}

.vykhari-case-study .text-wrapper-48 {
  line-height: 62.4px;
}

.vykhari-case-study .text-wrapper-49 {
  line-height: 53.8px;
}

.vykhari-case-study .text-wrapper-50 {
  line-height: 59.3px;
}

.vykhari-case-study .text-wrapper-51 {
  font-family: "Inter", Helvetica;
  line-height: 62.4px;
}

.vykhari-case-study .text-wrapper-52 {
  font-family: "Charter-Bold", Helvetica;
  font-weight: 700;
  line-height: 62.4px;
}

.vykhari-case-study .frame-30 {
  align-items: center;
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 41px;
  box-shadow: 7px 4px 24px #00000033;
  display: inline-flex;
  gap: 10px;
  height: 55px;
  justify-content: center;
  left: 648px;
  padding: 20px 24px;
  position: absolute;
  top: 322px;
}

.vykhari-case-study .component-12-instance {
  left: 553px !important;
  position: absolute !important;
  top: 5361px !important;
}

.vykhari-case-study .component-9-instance {
  left: 87px !important;
  position: absolute !important;
  top: 4808px !important;
}

.vykhari-case-study .component-10-instance {
  left: 553px !important;
  position: absolute !important;
  top: 4808px !important;
}

.vykhari-case-study .component-13-instance {
  left: 964px !important;
  position: absolute !important;
  top: 5361px !important;
}

.vykhari-case-study .component-3 {
  left: 197px !important;
}

.vykhari-case-study .component-11-instance {
  left: 87px !important;
  position: absolute !important;
  top: 5503px !important;
}

.vykhari-case-study .overlap-wrapper {
  background-color: #856649;
  height: 930px;
  left: 0;
  position: absolute;
  top: 2967px;
  width: 1441px;
}

.vykhari-case-study .overlap-8 {
  background-color: #143b35;
  height: 930px;
  position: relative;
}

.vykhari-case-study .frame-31 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 27px;
  left: 721px;
  position: absolute;
  top: 129px;
}

.vykhari-case-study .creating-fashion {
  color: transparent;
  font-family: "Charter-Regular", Helvetica;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: -0.4px;
  line-height: 48px;
  margin-top: -1px;
  position: relative;
  width: 577px;
}

.vykhari-case-study .text-wrapper-53 {
  color: #ffffff;
}

.vykhari-case-study .text-wrapper-54 {
  color: #e9a97d;
}

.vykhari-case-study .hi-i-am-ranjitha-a {
  color: #ffffff;
  font-family: "Proxima Nova-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.81px;
  line-height: 24.8px;
  position: relative;
  width: 575px;
}

.vykhari-case-study .component-14-instance {
  left: -4233px !important;
  top: 12788px !important;
}

.vykhari-case-study .component-4 {
  left: -4233px !important;
  top: 10903px !important;
}

.vykhari-case-study .component-5 {
  left: -4233px !important;
  top: 13258px !important;
}

.vykhari-case-study .component-6 {
  left: -4233px !important;
  top: 12151px !important;
}

.vykhari-case-study .component-7 {
  left: -4233px !important;
  top: 12567px !important;
}

.vykhari-case-study .frame-32 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
  left: 392px;
  position: absolute;
  top: 858px;
}

.vykhari-case-study .text-wrapper-55 {
  color: #143b35;
  font-family: "Charter-Roman", Helvetica;
  font-size: 48px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.vykhari-case-study .text-wrapper-56 {
  color: #143b35;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 28.1px;
  position: relative;
  width: 393px;
}

.vykhari-case-study .element-8 {
  height: 618px;
  left: -4145px;
  object-fit: cover;
  position: absolute;
  top: 21567px;
  width: 1264px;
}

.vykhari-case-study .vector-wrapper {
  border: 3px solid;
  border-color: #ffffff;
  border-radius: 27px;
  height: 54px;
  left: 705px;
  position: absolute;
  top: 8006px;
  width: 54px;
}

.vykhari-case-study .vector-3 {
  height: 21px;
  left: -4214px;
  position: absolute;
  top: 13880px;
  width: 16px;
}

.vykhari-case-study .footers {
  background-color: #143b35;
  height: 385px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 8382px;
  width: 1440px;
}

.vykhari-case-study .vykhari-2 {
  height: 77px;
  left: -4113px;
  position: absolute;
  top: 5557px;
  width: 126px;
}

.vykhari-case-study .company-links {
  height: 203px;
  left: 730px;
  position: absolute;
  top: 69px;
  width: 124px;
}

.vykhari-case-study .text-wrapper-57 {
  color: var(--white);
  font-family: "Proxima Nova-Bold", Helvetica;
  font-size: 18px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: -1px;
}

.vykhari-case-study .text-wrapper-58 {
  color: var(--primary-light);
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 46px;
  white-space: nowrap;
}

.vykhari-case-study .text-wrapper-59 {
  color: var(--primary-light);
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 90px;
  white-space: nowrap;
}

.vykhari-case-study .text-wrapper-60 {
  color: var(--primary-light);
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 627px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: 347px;
  white-space: nowrap;
}

.vykhari-case-study .resources-links {
  height: 203px;
  left: 933px;
  position: absolute;
  top: 69px;
  width: 129px;
}

.vykhari-case-study .product-links {
  height: 203px;
  left: 1137px;
  position: absolute;
  top: 69px;
  width: 112px;
}

.vykhari-case-study .divider {
  height: 1px;
  left: -4233px;
  object-fit: cover;
  position: absolute;
  top: 5820px;
  width: 1440px;
}

.vykhari-case-study .overlap-group-wrapper {
  height: 992px;
  left: 88px;
  position: absolute;
  top: 6668px;
  width: 1264px;
}

.vykhari-case-study .overlap-9 {
  height: 992px;
  position: relative;
}

.vykhari-case-study .frame-33 {
  height: 992px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1264px;
}

.vykhari-case-study .group-15 {
  height: 644px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1266px;
}

.vykhari-case-study .here-s-how-THE-wrapper {
  background-image: url(../../../static/img/dsc-5698-2.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 492px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1264px;
}

.vykhari-case-study .here-s-how-THE {
  color: #ffffff;
  font-family: "Proxima Nova-Medium", Helvetica;
  font-size: 32px;
  font-weight: 400;
  height: 77px;
  left: 41px;
  letter-spacing: -0.8px;
  line-height: 38.4px;
  position: absolute;
  top: 397px;
  width: 730px;
}

.vykhari-case-study .text-wrapper-61 {
  font-weight: 500;
}

.vykhari-case-study .text-wrapper-62 {
  font-family: "Charter-Regular", Helvetica;
}

.vykhari-case-study .group-16 {
  height: 34px;
  left: 41px;
  position: absolute;
  top: 492px;
  width: 506px;
}

.vykhari-case-study .text-wrapper-63 {
  color: #ffffff;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 18px;
  font-weight: 400;
  height: 22px;
  left: 0;
  letter-spacing: -0.45px;
  line-height: 21.6px;
  position: absolute;
  top: 6px;
  white-space: nowrap;
}

.vykhari-case-study .text-wrapper-64 {
  color: #ffffff;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 18px;
  font-weight: 400;
  height: 22px;
  left: 356px;
  letter-spacing: -0.45px;
  line-height: 21.6px;
  position: absolute;
  top: 6px;
  white-space: nowrap;
}

.vykhari-case-study .line-2 {
  height: 1px;
  left: 318px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1px;
}

.vykhari-case-study .overlap-10 {
  height: 348px;
  left: 0;
  position: absolute;
  top: 644px;
  width: 1266px;
}

.vykhari-case-study .group-17 {
  height: 348px;
  left: 0;
  position: absolute;
  top: 0;
  width: 431px;
}

.vykhari-case-study .element-reasons-to-buy-wrapper {
  background-color: #ddcdbd;
  height: 348px;
  position: relative;
  width: 429px;
}

.vykhari-case-study .element-reasons-to-buy {
  color: #000000;
  font-family: "Proxima Nova-Medium", Helvetica;
  font-size: 32px;
  font-weight: 400;
  height: 152px;
  left: 29px;
  letter-spacing: -0.8px;
  line-height: 38.4px;
  position: absolute;
  top: 137px;
  width: 351px;
}

.vykhari-case-study .text-wrapper-65 {
  font-family: "Charter-Bold", Helvetica;
  font-weight: 700;
}

.vykhari-case-study .group-18 {
  height: 348px;
  left: 429px;
  position: absolute;
  top: 0;
  width: 337px;
}

.vykhari-case-study .element-copy {
  height: 175px;
  left: -4321px;
  position: absolute;
  top: 7375px;
  width: 335px;
}

.vykhari-case-study .the-jewellery-INDIAN-wrapper {
  background-color: #966c49;
  height: 173px;
  left: 0;
  position: absolute;
  top: 0;
  width: 335px;
}

.vykhari-case-study .the-jewellery-INDIAN {
  color: #ffffff;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 51px;
  left: 30px;
  letter-spacing: -0.4px;
  line-height: 19.2px;
  position: absolute;
  top: 53px;
  width: 202px;
}

.vykhari-case-study .text-wrapper-66 {
  color: #ffffff;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.4px;
  line-height: 19.2px;
}

.vykhari-case-study .text-wrapper-67 {
  font-size: 24px;
  letter-spacing: -0.6px;
  line-height: 28.8px;
}

.vykhari-case-study .group-19 {
  height: 348px;
  left: 764px;
  position: absolute;
  top: 0;
  width: 502px;
}

.vykhari-case-study .IMG-6 {
  height: 348px;
  left: -4321px;
  object-fit: cover;
  position: absolute;
  top: 7202px;
  width: 500px;
}

.vykhari-case-study .text-wrapper-68 {
  color: #ffffff;
  font-family: "Proxima Nova-Semibold", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 29px;
  left: 29px;
  letter-spacing: -0.6px;
  line-height: 28.8px;
  position: absolute;
  top: 235px;
  width: 322px;
}

.vykhari-case-study .group-20 {
  height: 22px;
  left: 29px;
  position: absolute;
  top: 272px;
  width: 108px;
}

.vykhari-case-study .mask-group {
  height: 98px;
  left: -4292px;
  position: absolute;
  top: 7874px;
  width: 344px;
}

.vykhari-case-study .group-21 {
  height: 22px;
  left: 41px;
  position: absolute;
  top: 519px;
  width: 108px;
}

.vykhari-case-study .group-22 {
  height: 22px;
  left: 33px;
  position: absolute;
  top: 914px;
  width: 108px;
}

.vykhari-case-study .text-wrapper-69 {
  color: #000000;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 22.5px;
  position: absolute;
  text-decoration: underline;
  top: 0;
  width: 87px;
}

.vykhari-case-study .ellipse-7 {
  background-color: #000000;
  border-radius: 9px;
  height: 18px;
  left: 88px;
  position: absolute;
  top: 4px;
  width: 18px;
}

.vykhari-case-study .group-23 {
  height: 22px;
  left: 459px;
  position: absolute;
  top: 726px;
  width: 108px;
}

.vykhari-case-study .frame-34 {
  align-items: flex-start;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 1233px;
  left: 0;
  padding: 120px 160px;
  position: absolute;
  top: 7826px;
  width: 1920px;
}

.vykhari-case-study .text-wrapper-70 {
  color: #2f2f2f;
  font-family: "Neue Haas Grotesk Text Pro-75Bold", Helvetica;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.3px;
  line-height: 38.4px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vykhari-case-study .ellipse-8 {
  background-color: #143b35;
  border-radius: 9px;
  height: 18px;
  left: 164px;
  position: absolute;
  top: 207px;
  width: 18px;
}

.vykhari-case-study .ellipse-9 {
  background-color: #143b35;
  border-radius: 9px;
  height: 18px;
  left: 164px;
  position: absolute;
  top: 376px;
  width: 18px;
}

.vykhari-case-study .ellipse-10 {
  background-color: #143b35;
  border-radius: 9px;
  height: 18px;
  left: 164px;
  position: absolute;
  top: 545px;
  width: 18px;
}

.vykhari-case-study .ellipse-11 {
  background-color: #143b35;
  border-radius: 9px;
  height: 18px;
  left: 164px;
  position: absolute;
  top: 714px;
  width: 18px;
}

.vykhari-case-study .ellipse-12 {
  background-color: #143b35;
  border-radius: 9px;
  height: 18px;
  left: 164px;
  position: absolute;
  top: 882px;
  width: 18px;
}

.vykhari-case-study .ellipse-13 {
  background-color: #143b35;
  border-radius: 9px;
  height: 18px;
  left: 164px;
  position: absolute;
  top: 1050px;
  width: 18px;
}

.vykhari-case-study .visually-striking {
  color: #676768;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 40px;
  font-weight: 400;
  height: 896px;
  left: 206px;
  letter-spacing: -0.3px;
  line-height: 56px;
  position: absolute;
  top: 189px;
  width: 1608px;
}

.vykhari-case-study .overlap-11 {
  height: 969px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1920px;
}

.vykhari-case-study .frame-35 {
  align-items: flex-start;
  background-color: #143b35;
  display: flex;
  flex-direction: column;
  gap: 64px;
  height: 969px;
  left: 0;
  padding: 120px;
  position: absolute;
  top: 0;
  width: 1920px;
}

.vykhari-case-study .heading-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 260px;
  position: relative;
  width: 100%;
}

.vykhari-case-study .heading {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 1181px;
}

.vykhari-case-study .text-wrapper-71 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 96px;
  font-weight: 400;
  letter-spacing: -0.48px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.vykhari-case-study .digital-branding-web {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: -0.16px;
  line-height: 48px;
  position: relative;
  width: 888px;
}

.vykhari-case-study .group-24 {
  height: 108px;
  position: relative;
  width: 448px;
}

.vykhari-case-study .frame-36 {
  align-items: flex-start;
  display: inline-flex;
  gap: 16px;
  left: 0;
  position: absolute;
  top: 52px;
}

.vykhari-case-study .UIX {
  height: 56px;
  position: relative;
  width: 252px;
}

.vykhari-case-study .frame-37 {
  align-items: flex-start;
  background-color: #a7a7a71f;
  border-radius: 28px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 4px 16px 4px 4px;
  position: relative;
}

.vykhari-case-study .frame-38 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  position: relative;
}

.vykhari-case-study .group-25 {
  background-color: #ffffff;
  border-radius: 24px;
  height: 48px;
  position: relative;
  width: 48px;
}

.vykhari-case-study .overlap-group-5 {
  height: 22px;
  left: 13px;
  position: relative;
  top: 13px;
  width: 22px;
}

.vykhari-case-study .line-3 {
  height: 22px;
  left: 9px;
  position: absolute;
  top: 0;
  width: 5px;
}

.vykhari-case-study .line-4 {
  height: 4px;
  left: 0;
  position: absolute;
  top: 9px;
  width: 22px;
}

.vykhari-case-study .text-wrapper-72 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Display Pro-75Bold", Helvetica;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.46px;
  line-height: 32.9px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vykhari-case-study .UIX-2 {
  height: 56px;
  position: relative;
  width: 178px;
}

.vykhari-case-study .text-wrapper-73 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 24px;
  font-weight: 500;
  height: 40px;
  left: 0;
  letter-spacing: -0.39px;
  line-height: 40px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.vykhari-case-study .macbook-air-mockup {
  height: 969px;
  left: 593px;
  position: absolute;
  top: 0;
  width: 1327px;
}

.vykhari-case-study .element-9 {
  height: 1180px;
  left: 2px;
  position: absolute;
  top: 10028px;
  width: 1920px;
}

.vykhari-case-study .overlap-12 {
  height: 37px;
  left: 120px;
  position: absolute;
  top: -86px;
  width: 222px;
}

.vykhari-case-study .text-wrapper-74 {
  color: #2f2f2f;
  font-family: "Neue Haas Grotesk Display Pro-55Roman", Helvetica;
  font-size: 32px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.vykhari-case-study .frame-39 {
  background-color: #143b35;
  height: 1180px;
  left: 1024px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 896px;
}

.vykhari-case-study .overlap-group-6 {
  background-color: #143b35;
  border-radius: 2px;
  height: 1180px;
  left: -65px;
  position: relative;
  width: 961px;
}

.vykhari-case-study .overlap-13 {
  height: 769px;
  left: 191px;
  position: absolute;
  top: 89px;
  width: 627px;
}

.vykhari-case-study .text-wrapper-75 {
  color: #ffffff;
  font-family: "Charter-Roman", Helvetica;
  font-size: 549px;
  font-weight: 400;
  left: 0;
  letter-spacing: -2.75px;
  line-height: 768.6px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.vykhari-case-study .text-wrapper-76 {
  color: #ffffff;
  font-family: "Lexend Deca", Helvetica;
  font-size: 32px;
  font-weight: 400;
  left: 9px;
  letter-spacing: -0.16px;
  line-height: 44.8px;
  position: absolute;
  top: 39px;
  white-space: nowrap;
}

.vykhari-case-study .text-wrapper-77 {
  color: #ffffff;
  font-family: "Charter-Roman", Helvetica;
  font-size: 48px;
  font-weight: 400;
  left: 191px;
  letter-spacing: 0;
  line-height: 67.2px;
  position: absolute;
  top: 858px;
  width: 693px;
}

.vykhari-case-study .frame-40 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 1180px;
  left: 0;
  position: absolute;
  top: 0;
  width: 960px;
}

.vykhari-case-study .frame-41 {
  align-items: flex-start;
  display: inline-flex;
  height: 639px;
  position: relative;
}

.vykhari-case-study .frame-42 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #143b35;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: flex-end;
  padding: 80px 64px;
  position: relative;
  width: 640px;
}

.vykhari-case-study .frame-43 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  justify-content: flex-end;
  position: relative;
}

.vykhari-case-study .text-wrapper-78 {
  color: var(--core-colorswhite);
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.vykhari-case-study .frame-44 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
}

.vykhari-case-study .text-wrapper-79 {
  color: var(--core-colorswhite);
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.vykhari-case-study .frame-45 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #774c24;
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: flex-end;
  padding: 80px 64px;
  position: relative;
  width: 320px;
}

.vykhari-case-study .frame-46 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
}

.vykhari-case-study .frame-47 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  height: 341px;
  position: relative;
  width: 100%;
}

.vykhari-case-study .frame-48 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #c9f9df;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 32px;
  justify-content: flex-end;
  padding: 64px;
  position: relative;
}

.vykhari-case-study .text-wrapper-80 {
  color: #000000;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.vykhari-case-study .text-wrapper-81 {
  color: #000000;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.vykhari-case-study .frame-49 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #fff5e2;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 32px;
  justify-content: flex-end;
  padding: 64px;
  position: relative;
}

.vykhari-case-study .frame-50 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #1e131e;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 32px;
  justify-content: flex-end;
  padding: 64px;
  position: relative;
}

.vykhari-case-study .text-wrapper-82 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.vykhari-case-study .text-wrapper-83 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.vykhari-case-study .frame-51 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.vykhari-case-study .frame-52 {
  align-items: flex-start;
  background-color: #485058;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 32px;
  height: 200px;
  justify-content: flex-end;
  padding: 56px 64px;
  position: relative;
}

.vykhari-case-study .frame-53 {
  align-items: flex-start;
  background-color: #000000;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 32px;
  height: 200px;
  justify-content: flex-end;
  padding: 56px 64px;
  position: relative;
}

.vykhari-case-study .overlap-14 {
  height: 4734px;
  left: 0;
  position: absolute;
  top: 11259px;
  width: 1920px;
}

.vykhari-case-study .service {
  background-color: #ffffff;
  border: 10px solid;
  border-color: #d3d3d3;
  border-radius: 20px;
  height: 3184px;
  left: 53px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 978px;
}

.vykhari-case-study .div-3 {
  height: 485px;
  left: 0;
  position: absolute;
  top: 0;
  width: 971px;
}

.vykhari-case-study .overlap-group-7 {
  height: 485px;
  position: relative;
}

.vykhari-case-study .rectangle-10 {
  height: 485px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 968px;
}

.vykhari-case-study .frame-54 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 13.44px;
  left: 88px;
  position: absolute;
  top: 243px;
}

.vykhari-case-study .editorial-fashion {
  color: #ffffff;
  font-family: "Charter-Bold", Helvetica;
  font-size: 26.9px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 32.3px;
  margin-top: -0.67px;
  position: relative;
  width: 295.78px;
}

.vykhari-case-study .text-wrapper-84 {
  color: #ffffff;
  font-family: "Charter-Bold", Helvetica;
  font-size: 26.9px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 32.3px;
}

.vykhari-case-study .text-wrapper-85 {
  color: #ffffff;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 15.1px;
  position: relative;
  width: 224.52px;
}

.vykhari-case-study .component-25-instance {
  height: 15px !important;
  width: 79px !important;
}

.vykhari-case-study .component-8 {
  font-size: 10.8px !important;
  line-height: 15.1px !important;
  width: 65px !important;
}

.vykhari-case-study .component-15 {
  border-radius: 6.05px !important;
  height: 12px !important;
  left: 65px !important;
  top: 2px !important;
  width: 12px !important;
}

.vykhari-case-study .component-16 {
  height: 14.79px !important;
  position: relative !important;
  width: 76.63px !important;
}

.vykhari-case-study .header-2 {
  align-items: center;
  display: flex;
  gap: 468.54px;
  height: 64px;
  left: 0;
  padding: 16.13px 59.16px;
  position: absolute;
  top: 0;
  width: 968px;
}

.vykhari-case-study .vykhari-3 {
  height: 51.76px;
  margin-bottom: -10.08px;
  margin-top: -10.08px;
  position: relative;
  width: 84.7px;
}

.vykhari-case-study .frame-55 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 26.22px;
  margin-bottom: -2.69px;
  margin-top: -2.69px;
  position: relative;
}

.vykhari-case-study .frame-56 {
  align-items: center;
  display: flex;
  position: relative;
  width: 46.38px;
}

.vykhari-case-study .text-wrapper-86 {
  color: #ffffff;
  font-family: "Proxima Nova-Light", Helvetica;
  font-size: 10.8px;
  font-weight: 300;
  letter-spacing: -0.11px;
  line-height: 15.7px;
  margin-right: -19.49px;
  margin-top: -0.67px;
  position: relative;
  width: 65.88px;
}

.vykhari-case-study .frame-57 {
  align-items: center;
  display: flex;
  position: relative;
  width: 45.04px;
}

.vykhari-case-study .text-wrapper-87 {
  color: #ffffff;
  font-family: "Proxima Nova-Light", Helvetica;
  font-size: 10.8px;
  font-weight: 300;
  letter-spacing: -0.11px;
  line-height: 15.7px;
  margin-right: -14.12px;
  margin-top: -0.67px;
  position: relative;
  width: 59.16px;
}

.vykhari-case-study .frame-58 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.72px;
  height: 19.49px;
  justify-content: center;
  padding: 6.72px;
  position: relative;
}

.vykhari-case-study .text-wrapper-88 {
  color: #ffffff;
  font-family: "Proxima Nova-Light", Helvetica;
  font-size: 10.8px;
  font-weight: 300;
  letter-spacing: -0.11px;
  line-height: 15.7px;
  margin-bottom: -4.3px;
  margin-top: -5.65px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vykhari-case-study .frame-59 {
  align-items: center;
  border: 0.67px solid;
  border-color: #ffffff;
  border-radius: 27.56px;
  box-shadow: 4.71px 2.69px 16.13px #00000033;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.72px;
  height: 36.97px;
  justify-content: center;
  padding: 13.44px 16.13px;
  position: relative;
}

.vykhari-case-study .text-wrapper-89 {
  color: #ffffff;
  font-family: "Proxima Nova-Light", Helvetica;
  font-size: 12.1px;
  font-weight: 300;
  letter-spacing: -0.3px;
  line-height: 14.5px;
  margin-bottom: -1.79px;
  margin-top: -3.13px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vykhari-case-study .text-wrapper-90 {
  color: #303030;
  font-family: "Livvic", Helvetica;
  font-size: 26.9px;
  font-weight: 600;
  left: -621px;
  letter-spacing: -0.4px;
  line-height: normal;
  position: absolute;
  top: 1782px;
  width: 251px;
}

.vykhari-case-study .footers-2 {
  background-color: #143b35;
  height: 259px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 4086px;
  width: 968px;
}

.vykhari-case-study .vykhari-4 {
  height: 52px;
  left: -3983px;
  position: absolute;
  top: 174px;
  width: 85px;
}

.vykhari-case-study .company-links-2 {
  height: 136px;
  left: 491px;
  position: absolute;
  top: 46px;
  width: 83px;
}

.vykhari-case-study .text-wrapper-91 {
  color: var(--white);
  font-family: "Proxima Nova-Bold", Helvetica;
  font-size: 12.1px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: -1px;
}

.vykhari-case-study .text-wrapper-92 {
  color: var(--primary-light);
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 16.1px;
  position: absolute;
  top: 31px;
  white-space: nowrap;
}

.vykhari-case-study .text-wrapper-93 {
  color: var(--primary-light);
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 16.1px;
  position: absolute;
  top: 61px;
  white-space: nowrap;
}

.vykhari-case-study .text-wrapper-94 {
  color: var(--primary-light);
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 9.4px;
  font-weight: 400;
  left: 421px;
  letter-spacing: 0;
  line-height: 14.1px;
  position: absolute;
  top: 233px;
  white-space: nowrap;
}

.vykhari-case-study .resources-links-2 {
  height: 136px;
  left: 627px;
  position: absolute;
  top: 46px;
  width: 87px;
}

.vykhari-case-study .product-links-2 {
  height: 136px;
  left: 764px;
  position: absolute;
  top: 46px;
  width: 75px;
}

.vykhari-case-study .divider-2 {
  height: 1px;
  left: -4064px;
  position: absolute;
  top: 350px;
  width: 968px;
}

.vykhari-case-study .group-26 {
  height: 341px;
  left: 0;
  position: absolute;
  top: 3744px;
  width: 968px;
}

.vykhari-case-study .DSC-copy-7 {
  height: 341px;
  left: -4064px;
  position: absolute;
  top: 4213px;
  width: 968px;
}

.vykhari-case-study .frame-60 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 23.53px;
  left: 153px;
  position: absolute;
  top: 116px;
}

.vykhari-case-study .text-wrapper-95 {
  color: #ffffff;
  font-family: "Charter-Bold", Helvetica;
  font-size: 35px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 49.1px;
  margin-top: -0.67px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.vykhari-case-study .frame-61 {
  align-items: center;
  border: 0.67px solid;
  border-color: #ffffff;
  border-radius: 27.56px;
  box-shadow: 4.71px 2.69px 16.13px #00000033;
  display: inline-flex;
  gap: 6.72px;
  height: 36.97px;
  justify-content: center;
  padding: 13.44px 16.13px;
  position: relative;
}

.vykhari-case-study .text-wrapper-96 {
  color: #ffffff;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 12.1px;
  font-weight: 400;
  letter-spacing: -0.3px;
  line-height: 14.5px;
  margin-bottom: -1.79px;
  margin-top: -3.13px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vykhari-case-study .overlap-15 {
  height: 417px;
  left: 0;
  position: absolute;
  top: 1352px;
  width: 968px;
}

.vykhari-case-study .rectangle-11 {
  height: 415px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 968px;
}

.vykhari-case-study .screenshot {
  height: 417px;
  left: 312px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 348px;
}

.vykhari-case-study .image-2 {
  height: 415px;
  left: 312px;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 597px;
}

.vykhari-case-study .rectangle-12 {
  height: 415px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 312px;
}

.vykhari-case-study .rectangle-13 {
  height: 415px;
  left: 655px;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 313px;
}

.vykhari-case-study .component-26-instance {
  height: 45px !important;
  left: 355px !important;
  position: absolute !important;
  top: 165px !important;
  width: 262px !important;
}

.vykhari-case-study .component-17 {
  height: 323px !important;
  width: 272px !important;
}

.vykhari-case-study .component-18 {
  font-size: 32.3px !important;
  left: 2px !important;
  line-height: 45.3px !important;
}

.vykhari-case-study .component-19 {
  font-size: 32.3px !important;
  left: 56px !important;
  line-height: 45.3px !important;
  top: 62px !important;
}

.vykhari-case-study .component-20 {
  font-size: 32.3px !important;
  left: 22px !important;
  line-height: 45.3px !important;
  top: 134px !important;
}

.vykhari-case-study .component-21 {
  font-size: 32.3px !important;
  left: 55px !important;
  line-height: 45.3px !important;
  top: 206px !important;
}

.vykhari-case-study .component-23 {
  font-size: 32.3px !important;
  line-height: 45.3px !important;
  top: 278px !important;
}

.vykhari-case-study .group-27 {
  height: 15px;
  left: 450px;
  position: absolute;
  top: 235px;
  width: 73px;
}

.vykhari-case-study .text-wrapper-97 {
  color: #ffffff;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 15.1px;
  position: absolute;
  text-decoration: underline;
  top: 0;
  width: 52px;
}

.vykhari-case-study .ellipse-14 {
  background-color: #ffffff;
  border-radius: 6.05px;
  height: 12px;
  left: 59px;
  position: absolute;
  top: 3px;
  width: 12px;
}

.vykhari-case-study .group-wrapper {
  background-image: url(../../../static/img/hn-01017-1-1.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 442px;
  left: 0;
  position: absolute;
  top: 1769px;
  width: 968px;
}

.vykhari-case-study .group-28 {
  border: 2.02px solid;
  border-color: #ffffff;
  border-radius: 18.15px;
  height: 36px;
  left: 461px;
  position: relative;
  top: 195px;
  width: 36px;
}

.vykhari-case-study .vector-4 {
  height: 16px;
  left: 12px;
  position: absolute;
  top: 8px;
  width: 13px;
}

.vykhari-case-study .overlap-16 {
  height: 1420px;
  left: -204px;
  position: absolute;
  top: 2324px;
  width: 1377px;
}

.vykhari-case-study .overlap-17 {
  height: 1420px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 1377px;
}

.vykhari-case-study .group-29 {
  background-color: #143b35;
  height: 425px;
  left: 204px;
  position: absolute;
  top: 0;
  width: 496px;
}

.vykhari-case-study .overlap-18 {
  height: 380px;
  left: 42px;
  position: relative;
  top: 22px;
  width: 430px;
}

.vykhari-case-study .HN-2 {
  height: 380px;
  left: 144px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 286px;
}

.vykhari-case-study .frame-62 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 18.82px;
  left: 0;
  position: absolute;
  top: 11px;
  width: 210px;
}

.vykhari-case-study .MEN-s-STYLING {
  color: #ffffff;
  font-family: "Charter-Bold", Helvetica;
  font-size: 26.9px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 27.7px;
  margin-top: -0.67px;
  position: relative;
  text-align: right;
  width: 180.16px;
}

.vykhari-case-study .group-30 {
  height: 15px;
  position: relative;
  width: 75.94px;
}

.vykhari-case-study .text-wrapper-98 {
  color: #ffffff;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 15.1px;
  position: absolute;
  text-decoration: underline;
  top: 0;
  width: 58px;
}

.vykhari-case-study .ellipse-15 {
  background-color: #ffffff;
  border-radius: 6.05px;
  height: 12px;
  left: 62px;
  position: absolute;
  top: 3px;
  width: 12px;
}

.vykhari-case-study .overlap-19 {
  height: 996px;
  left: 0;
  position: absolute;
  top: 424px;
  width: 1377px;
}

.vykhari-case-study .group-31 {
  height: 425px;
  left: 204px;
  position: absolute;
  top: 1px;
  width: 499px;
}

.vykhari-case-study .overlap-20 {
  background-image: url(../../../static/img/x5a0489-1.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 425px;
  position: relative;
  width: 497px;
}

.vykhari-case-study .text-wrapper-99 {
  color: #ffffff;
  font-family: "Charter-Bold", Helvetica;
  font-size: 26.9px;
  font-weight: 700;
  left: 225px;
  letter-spacing: 0;
  line-height: 27.7px;
  position: absolute;
  text-align: right;
  top: 309px;
  width: 248px;
}

.vykhari-case-study .group-32 {
  height: 15px;
  left: 385px;
  position: absolute;
  top: 350px;
  width: 87px;
}

.vykhari-case-study .text-wrapper-100 {
  color: #ffffff;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 15.1px;
  position: absolute;
  text-decoration: underline;
  top: 0;
  width: 68px;
}

.vykhari-case-study .ellipse-16 {
  background-color: #ffffff;
  border-radius: 6.05px;
  height: 12px;
  left: 72px;
  position: absolute;
  top: 3px;
  width: 12px;
}

.vykhari-case-study .ellipse-17 {
  border: 0.67px solid;
  border-color: #ffffff;
  border-radius: 150.92px/129.07px;
  height: 258px;
  left: 65px;
  position: absolute;
  top: 23px;
  width: 302px;
}

.vykhari-case-study .element-10 {
  background-color: #dfcebb;
  height: 425px;
  left: 701px;
  position: absolute;
  top: 0;
  width: 471px;
}

.vykhari-case-study .group-33 {
  height: 15px;
  left: 1066px;
  position: absolute;
  top: 387px;
  width: 84px;
}

.vykhari-case-study .text-wrapper-101 {
  color: #143b35;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 15.1px;
  position: absolute;
  text-decoration: underline;
  top: 0;
  width: 65px;
}

.vykhari-case-study .ellipse-18 {
  background-color: #143b35;
  border-radius: 6.71px/6.05px;
  height: 12px;
  left: 69px;
  position: absolute;
  top: 3px;
  width: 13px;
}

.vykhari-case-study .e-COMMERCE-styling {
  color: #143b35;
  font-family: "Charter-Bold", Helvetica;
  font-size: 26.9px;
  font-weight: 700;
  left: 821px;
  letter-spacing: 0;
  line-height: 37.8px;
  position: absolute;
  text-align: right;
  top: 29px;
  width: 255px;
}

.vykhari-case-study .text-wrapper-102 {
  color: #143b35;
  font-family: "Charter-Bold", Helvetica;
  font-size: 26.9px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 37.8px;
}

.vykhari-case-study .text-wrapper-103 {
  color: #143b35;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  left: 724px;
  letter-spacing: 0;
  line-height: 15.1px;
  position: absolute;
  text-align: right;
  top: 267px;
  width: 12px;
}

.vykhari-case-study .text-wrapper-104 {
  color: #143b35;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  left: 847px;
  letter-spacing: 0;
  line-height: 15.1px;
  position: absolute;
  text-align: right;
  top: 363px;
  width: 15px;
}

.vykhari-case-study .text-wrapper-105 {
  color: #143b35;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  left: 1040px;
  letter-spacing: 0;
  line-height: 15.1px;
  position: absolute;
  text-align: right;
  top: 267px;
  width: 14px;
}

.vykhari-case-study .element-11 {
  height: 244px;
  left: 847px;
  object-fit: cover;
  position: absolute;
  top: 114px;
  width: 180px;
}

.vykhari-case-study .element-12 {
  height: 147px;
  left: 724px;
  object-fit: cover;
  position: absolute;
  top: 114px;
  width: 109px;
}

.vykhari-case-study .element-13 {
  height: 147px;
  left: 1040px;
  object-fit: cover;
  position: absolute;
  top: 114px;
  width: 109px;
}

.vykhari-case-study .rectangle-14 {
  background-color: #143b35;
  height: 571px;
  left: 204px;
  position: absolute;
  top: 425px;
  width: 968px;
}

.vykhari-case-study .frame-63 {
  align-items: center;
  display: inline-flex;
  gap: 26.78px;
  left: 0;
  position: absolute;
  top: 509px;
}

.vykhari-case-study .rectangle-15 {
  height: 268.61px;
  margin-bottom: -888.05px;
  margin-left: -3859.64px;
  object-fit: cover;
  position: relative;
  width: 230.95px;
}

.vykhari-case-study .rectangle-16 {
  height: 268.61px;
  margin-bottom: -888.05px;
  margin-left: -3601.91px;
  object-fit: cover;
  position: relative;
  width: 230.95px;
}

.vykhari-case-study .rectangle-17 {
  height: 403.33px;
  margin-bottom: -955.42px;
  margin-left: -3344.18px;
  object-fit: cover;
  position: relative;
  width: 346.43px;
}

.vykhari-case-study .rectangle-18 {
  height: 268.61px;
  margin-bottom: -888.05px;
  margin-left: -2970.97px;
  object-fit: cover;
  position: relative;
  width: 230.95px;
}

.vykhari-case-study .rectangle-19 {
  height: 268.61px;
  margin-bottom: -888.05px;
  margin-left: -2713.24px;
  object-fit: cover;
  position: relative;
  width: 230.95px;
}

.vykhari-case-study .text-wrapper-106 {
  color: #143b35;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  left: 860px;
  letter-spacing: 0;
  line-height: 15.1px;
  position: absolute;
  text-align: right;
  top: 67px;
  width: 178px;
}

.vykhari-case-study .group-34 {
  height: 425px;
  left: 700px;
  position: absolute;
  top: 0;
  width: 478px;
}

.vykhari-case-study .overlap-21 {
  background-image: url(../../../static/img/18-1.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 425px;
  position: relative;
  width: 472px;
}

.vykhari-case-study .group-35 {
  height: 15px;
  left: 361px;
  position: absolute;
  top: 356px;
  width: 84px;
}

.vykhari-case-study .text-wrapper-107 {
  color: #ffffff;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 15.1px;
  position: absolute;
  text-decoration: underline;
  top: 0;
  width: 65px;
}

.vykhari-case-study .ellipse-19 {
  background-color: #ffffff;
  border-radius: 6.72px/6.05px;
  height: 12px;
  left: 69px;
  position: absolute;
  top: 3px;
  width: 13px;
}

.vykhari-case-study .overlap-group-8 {
  height: 49px;
  left: 217px;
  position: absolute;
  top: 278px;
  width: 225px;
}

.vykhari-case-study .text-wrapper-108 {
  color: #ffffff;
  font-family: "Charter-Bold", Helvetica;
  font-size: 26.9px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: 37.8px;
  position: absolute;
  text-align: right;
  top: 11px;
  width: 225px;
}

.vykhari-case-study .text-wrapper-109 {
  color: #ffffff;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  left: 31px;
  letter-spacing: 0;
  line-height: 15.1px;
  position: absolute;
  text-align: right;
  top: 0;
  width: 194px;
}

.vykhari-case-study .text-wrapper-110 {
  color: #ffffff;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 8.1px;
  font-weight: 400;
  left: 352px;
  letter-spacing: 0;
  line-height: 11.3px;
  position: absolute;
  text-align: right;
  top: 331px;
  width: 91px;
}

.vykhari-case-study .frame-64 {
  align-items: center;
  display: inline-flex;
  gap: 108.23px;
  left: 42px;
  position: absolute;
  top: 2247px;
}

.vykhari-case-study .frame-65 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 76.63px;
  justify-content: center;
  position: relative;
}

.vykhari-case-study .discover-more {
  color: #143b35;
  font-family: "Charter-Bold", Helvetica;
  font-size: 26.9px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 37.8px;
  position: relative;
  width: 221.16px;
}

.vykhari-case-study .text-wrapper-111 {
  color: #000000;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 16.1px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 22.7px;
  margin-top: -0.67px;
  position: relative;
  width: 359.64px;
}

.vykhari-case-study .frame-66 {
  align-items: center;
  border: 0.67px solid;
  border-color: #000000;
  border-radius: 27.56px;
  box-shadow: 4.71px 2.69px 16.13px #00000033;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.72px;
  height: 36.97px;
  justify-content: center;
  padding: 13.44px 16.13px;
  position: relative;
}

.vykhari-case-study .text-wrapper-112 {
  color: #000000;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 12.1px;
  font-weight: 400;
  letter-spacing: -0.3px;
  line-height: 14.5px;
  margin-bottom: -1.79px;
  margin-top: -3.13px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vykhari-case-study .component-33-instance {
  height: 790px !important;
  left: 52px !important;
  position: absolute !important;
  top: 522px !important;
  width: 858px !important;
}

.vykhari-case-study .component-24 {
  height: 697px !important;
  left: 7px !important;
  top: 93px !important;
  width: 852px !important;
}

.vykhari-case-study .component-28 {
  height: 697px !important;
  left: 2px !important;
  overflow: hidden !important;
  position: absolute !important;
  top: 0 !important;
  width: 850px !important;
}

.vykhari-case-study .component-29 {
  height: 358px !important;
  top: 339px !important;
  width: 328px !important;
}

.vykhari-case-study .component-30 {
  height: 697px !important;
  left: 352px !important;
  width: 498px !important;
}

.vykhari-case-study .component-31 {
  gap: 65.21px !important;
  top: 32px !important;
}

.vykhari-case-study .component-32 {
  gap: 23.53px !important;
}

.vykhari-case-study .component-34 {
  font-size: 26.9px !important;
  line-height: 32.3px !important;
  margin-top: -0.67px !important;
  width: 288.38px !important;
}

.vykhari-case-study .component-35 {
  font-size: 10.8px !important;
  line-height: 15.1px !important;
  width: 288.38px !important;
}

.vykhari-case-study .component-36 {
  height: 15px !important;
  width: 129.72px !important;
}

.vykhari-case-study .component-37 {
  font-size: 10.8px !important;
  line-height: 15.1px !important;
  width: 111px !important;
}

.vykhari-case-study .component-38 {
  border-radius: 6.05px !important;
  height: 12px !important;
  left: 116px !important;
  top: 1px !important;
  width: 12px !important;
}

.vykhari-case-study .component-39 {
  gap: 49.74px !important;
}

.vykhari-case-study .component-40 {
  border-bottom-width: 0.67px !important;
  gap: 6.72px !important;
  padding: 6.72px !important;
}

.vykhari-case-study .component-41 {
  font-size: 13.4px !important;
  line-height: 16.1px !important;
  margin-top: -0.67px !important;
}

.vykhari-case-study .component-42 {
  gap: 6.72px !important;
  padding: 6.72px !important;
}

.vykhari-case-study .work {
  background-color: #ffffff;
  border: 10px solid;
  border-color: #d3d3d3;
  border-radius: 20px;
  height: 3184px;
  left: 1076px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 780px;
}

.vykhari-case-study .overlap-22 {
  background-color: #fff3eb;
  height: 153px;
  left: 0;
  position: absolute;
  top: 4107px;
  width: 770px;
}

.vykhari-case-study .frame-67 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 13.9px;
  left: 133px;
  position: relative;
  top: 19px;
}

.vykhari-case-study .text-wrapper-113 {
  color: #000000;
  font-family: "Charter-Bold", Helvetica;
  font-size: 21.4px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 25.7px;
  margin-top: -0.53px;
  position: relative;
  width: 229.4px;
}

.vykhari-case-study .frame-68 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 48.59px;
  position: relative;
}

.vykhari-case-study .image-3 {
  height: 34.82px;
  margin-bottom: -24.1px;
  margin-left: -5220.15px;
  position: relative;
  width: 137.26px;
}

.vykhari-case-study .image-4 {
  height: 40.09px;
  margin-bottom: -26.73px;
  margin-left: -5034.29px;
  object-fit: cover;
  position: relative;
  width: 61.14px;
}

.vykhari-case-study .image-5 {
  height: 39.28px;
  margin-bottom: -26.33px;
  margin-left: -4924.56px;
  object-fit: cover;
  position: relative;
  width: 78.55px;
}

.vykhari-case-study .image-6 {
  height: 80.98px;
  margin-bottom: -47.18px;
  margin-left: -4797.42px;
  object-fit: cover;
  position: relative;
  width: 80.98px;
}

.vykhari-case-study .overlap-23 {
  height: 543px;
  left: 0;
  position: absolute;
  top: 0;
  width: 775px;
}

.vykhari-case-study .component-1-instance {
  height: 543px !important;
  left: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  top: 0 !important;
  width: 775px !important;
}

.vykhari-case-study .component-43 {
  height: 543px !important;
  width: 770px !important;
}

.vykhari-case-study .component-44 {
  height: 482px !important;
  top: 49px !important;
  width: 770px !important;
}

.vykhari-case-study .component-45 {
  height: 404px !important;
  left: 253px !important;
  top: 83px !important;
  width: 264px !important;
}

.vykhari-case-study .component-46 {
  height: 229px !important;
  left: 529px !important;
  top: 190px !important;
  width: 152px !important;
}

.vykhari-case-study .component-47 {
  height: 229px !important;
  left: 695px !important;
  top: 190px !important;
  width: 75px !important;
}

.vykhari-case-study .component-48 {
  height: 229px !important;
  top: 190px !important;
  width: 74px !important;
}

.vykhari-case-study .component-49 {
  height: 229px !important;
  left: 87px !important;
  top: 190px !important;
  width: 153px !important;
}

.vykhari-case-study .component-50 {
  gap: 2.14px !important;
  left: 47px !important;
  top: 107px !important;
}

.vykhari-case-study .component-51 {
  font-size: 21.4px !important;
  line-height: 30.1px !important;
  margin-top: -0.53px !important;
  width: 143.84px !important;
}

.vykhari-case-study .component-52 {
  font-size: 8.6px !important;
  line-height: 12px !important;
  width: 178.6px !important;
}

.vykhari-case-study .component-53 {
  font-size: 8.6px !important;
  left: 529px !important;
  line-height: 12px !important;
  top: 439px !important;
  width: 179px !important;
}

.vykhari-case-study .header-3 {
  align-items: center;
  background-color: #143b35a3;
  display: flex;
  gap: 372.7px;
  height: 51px;
  left: 0;
  padding: 12.83px 47.06px;
  position: absolute;
  top: 0;
  width: 770px;
}

.vykhari-case-study .vykhari-5 {
  height: 41.17px;
  margin-bottom: -8.02px;
  margin-top: -8.02px;
  position: relative;
  width: 67.38px;
}

.vykhari-case-study .frame-69 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20.85px;
  margin-bottom: -2.14px;
  margin-top: -2.14px;
  position: relative;
}

.vykhari-case-study .frame-70 {
  align-items: center;
  display: flex;
  position: relative;
  width: 36.9px;
}

.vykhari-case-study .text-wrapper-114 {
  color: #ffffff;
  font-family: "Proxima Nova-Light", Helvetica;
  font-size: 8.6px;
  font-weight: 300;
  letter-spacing: -0.09px;
  line-height: 12.5px;
  margin-right: -15.51px;
  margin-top: -0.53px;
  position: relative;
  width: 52.4px;
}

.vykhari-case-study .frame-71 {
  align-items: center;
  display: flex;
  position: relative;
  width: 35.83px;
}

.vykhari-case-study .text-wrapper-115 {
  color: #ffffff;
  font-family: "Proxima Nova-Light", Helvetica;
  font-size: 8.6px;
  font-weight: 300;
  letter-spacing: -0.09px;
  line-height: 12.5px;
  margin-right: -11.23px;
  margin-top: -0.53px;
  position: relative;
  width: 47.06px;
}

.vykhari-case-study .frame-72 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5.35px;
  height: 15.51px;
  justify-content: center;
  padding: 5.35px;
  position: relative;
}

.vykhari-case-study .text-wrapper-116 {
  color: #ffffff;
  font-family: "Proxima Nova-Light", Helvetica;
  font-size: 8.6px;
  font-weight: 300;
  letter-spacing: -0.09px;
  line-height: 12.5px;
  margin-bottom: -3.06px;
  margin-top: -4.13px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vykhari-case-study .frame-73 {
  align-items: center;
  border: 0.53px solid;
  border-color: #ffffff;
  border-radius: 21.92px;
  box-shadow: 3.74px 2.14px 12.83px #00000033;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5.35px;
  height: 29.41px;
  justify-content: center;
  padding: 10.69px 12.83px;
  position: relative;
}

.vykhari-case-study .text-wrapper-117 {
  color: #ffffff;
  font-family: "Proxima Nova-Light", Helvetica;
  font-size: 9.6px;
  font-weight: 300;
  letter-spacing: -0.24px;
  line-height: 11.6px;
  margin-bottom: -1.45px;
  margin-top: -2.52px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vykhari-case-study .text-wrapper-118 {
  color: #303030;
  font-family: "Livvic", Helvetica;
  font-size: 21.4px;
  font-weight: 600;
  left: -494px;
  letter-spacing: -0.32px;
  line-height: normal;
  position: absolute;
  top: 1417px;
  width: 199px;
}

.vykhari-case-study .group-36 {
  height: 12px;
  left: 663px;
  position: absolute;
  top: 4069px;
  width: 61px;
}

.vykhari-case-study .text-wrapper-119 {
  color: #000000;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 8.6px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 12px;
  position: absolute;
  text-decoration: underline;
  top: 0;
  width: 47px;
}

.vykhari-case-study .ellipse-20 {
  background-color: #000000;
  border-radius: 4.81px;
  height: 10px;
  left: 49px;
  position: absolute;
  top: 2px;
  width: 10px;
}

.vykhari-case-study .footers-3 {
  background-color: #143b35;
  height: 206px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 4260px;
  width: 770px;
}

.vykhari-case-study .vykhari-6 {
  height: 41px;
  left: -5023px;
  position: absolute;
  top: -10px;
  width: 67px;
}

.vykhari-case-study .company-links-3 {
  height: 109px;
  left: 390px;
  position: absolute;
  top: 37px;
  width: 66px;
}

.vykhari-case-study .text-wrapper-120 {
  color: var(--white);
  font-family: "Proxima Nova-Bold", Helvetica;
  font-size: 9.6px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: -1px;
}

.vykhari-case-study .text-wrapper-121 {
  color: var(--primary-light);
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 8.6px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 12.8px;
  position: absolute;
  top: 25px;
  white-space: nowrap;
}

.vykhari-case-study .text-wrapper-122 {
  color: var(--primary-light);
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 8.6px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 12.8px;
  position: absolute;
  top: 48px;
  white-space: nowrap;
}

.vykhari-case-study .text-wrapper-123 {
  color: var(--primary-light);
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 7.5px;
  font-weight: 400;
  left: 335px;
  letter-spacing: 0;
  line-height: 11.2px;
  position: absolute;
  top: 186px;
  white-space: nowrap;
}

.vykhari-case-study .resources-links-3 {
  height: 109px;
  left: 499px;
  position: absolute;
  top: 37px;
  width: 69px;
}

.vykhari-case-study .product-links-3 {
  height: 109px;
  left: 608px;
  position: absolute;
  top: 37px;
  width: 60px;
}

.vykhari-case-study .divider-3 {
  height: 1px;
  left: -5087px;
  position: absolute;
  top: 131px;
  width: 770px;
}

.vykhari-case-study .frame-74 {
  height: 3454px;
  left: 47px;
  overflow: hidden;
  position: absolute;
  top: 590px;
  width: 676px;
}

.vykhari-case-study .frame-75 {
  align-items: flex-end;
  display: inline-flex;
  gap: 293.56px;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
}

.vykhari-case-study .frame-76 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 11.76px;
  position: relative;
}

.vykhari-case-study .frame-77 {
  align-items: flex-start;
  border: 0.53px solid;
  border-color: #000000;
  border-radius: 4.28px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5.35px;
  padding: 5.35px;
  position: relative;
}

.vykhari-case-study .text-wrapper-124 {
  color: #143b35;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 8.6px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 12px;
  margin-top: -0.53px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vykhari-case-study .frame-78 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2.14px;
  height: 27.27px;
  position: relative;
  width: 172.18px;
}

.vykhari-case-study .text-wrapper-125 {
  color: #143b35;
  font-family: "Charter-Bold", Helvetica;
  font-size: 19.2px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 27px;
  margin-top: -0.53px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vykhari-case-study .text-wrapper-126 {
  color: #143b35;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 10.7px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 15px;
  position: relative;
  text-align: right;
  width: 210.15px;
}

.vykhari-case-study .frame-79 {
  align-items: flex-start;
  display: inline-flex;
  gap: 10.16px;
  height: 302px;
  left: 0;
  position: absolute;
  top: 335px;
}

.vykhari-case-study .rectangle-20 {
  height: 301.58px;
  object-fit: cover;
  position: relative;
  width: 218.17px;
}

.vykhari-case-study .rectangle-21 {
  height: 301.58px;
  object-fit: cover;
  position: relative;
  width: 217.63px;
}

.vykhari-case-study .rectangle-22 {
  height: 235px;
  left: 456px;
  object-fit: cover;
  position: absolute;
  top: 89px;
  width: 220px;
}

.vykhari-case-study .rectangle-23 {
  height: 236px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 88px;
  width: 446px;
}

.vykhari-case-study .frame-80 {
  align-items: flex-end;
  display: inline-flex;
  gap: 293.56px;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 674px;
}

.vykhari-case-study .frame-81 {
  align-items: flex-start;
  display: inline-flex;
  gap: 10.16px;
  height: 302px;
  left: 0;
  position: absolute;
  top: 1010px;
}

.vykhari-case-study .rectangle-24 {
  height: 235px;
  left: 456px;
  object-fit: cover;
  position: absolute;
  top: 764px;
  width: 220px;
}

.vykhari-case-study .rectangle-25 {
  height: 236px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 762px;
  width: 446px;
}

.vykhari-case-study .frame-82 {
  align-items: flex-end;
  display: inline-flex;
  gap: 293.56px;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 1350px;
}

.vykhari-case-study .frame-83 {
  align-items: flex-start;
  display: inline-flex;
  gap: 10.16px;
  height: 302px;
  left: 0;
  position: absolute;
  top: 1685px;
}

.vykhari-case-study .rectangle-26 {
  height: 235px;
  left: 456px;
  object-fit: cover;
  position: absolute;
  top: 1439px;
  width: 220px;
}

.vykhari-case-study .rectangle-27 {
  height: 236px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 1437px;
  width: 446px;
}

.vykhari-case-study .frame-84 {
  align-items: flex-end;
  display: inline-flex;
  gap: 293.56px;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 2025px;
}

.vykhari-case-study .frame-85 {
  align-items: flex-start;
  display: inline-flex;
  gap: 10.16px;
  height: 302px;
  left: 0;
  position: absolute;
  top: 2419px;
}

.vykhari-case-study .rectangle-28 {
  height: 165.65px;
  object-fit: cover;
  position: relative;
  width: 218.17px;
}

.vykhari-case-study .rectangle-29 {
  height: 165.65px;
  object-fit: cover;
  position: relative;
  width: 217.63px;
}

.vykhari-case-study .MG-copy-4 {
  height: 295px;
  left: 0;
  position: absolute;
  top: 2113px;
  width: 676px;
}

.vykhari-case-study .frame-86 {
  align-items: flex-end;
  display: inline-flex;
  gap: 293.56px;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 2759px;
}

.vykhari-case-study .frame-87 {
  align-items: flex-start;
  display: inline-flex;
  gap: 10.16px;
  height: 302px;
  left: 0;
  position: absolute;
  top: 3152px;
}

.vykhari-case-study .rectangle-30 {
  height: 301.58px;
  margin-bottom: -471.01px;
  margin-left: -5134.06px;
  object-fit: cover;
  position: relative;
  width: 218.17px;
}

.vykhari-case-study .rectangle-31 {
  height: 301.58px;
  margin-bottom: -471.01px;
  margin-left: -4905.73px;
  object-fit: cover;
  position: relative;
  width: 217.63px;
}

.vykhari-case-study .rectangle-32 {
  height: 301.58px;
  margin-bottom: -471.01px;
  margin-left: -4677.94px;
  object-fit: cover;
  position: relative;
  width: 218.17px;
}

.vykhari-case-study .MG-copy-5 {
  height: 295px;
  left: -5134px;
  position: absolute;
  top: 6470px;
  width: 344px;
}

.vykhari-case-study .MG-copy-6 {
  height: 295px;
  left: -4781px;
  position: absolute;
  top: 6470px;
  width: 321px;
}

.vykhari-case-study .frame-88 {
  align-items: flex-end;
  display: inline-flex;
  gap: 293.56px;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 3491px;
}

.vykhari-case-study .frame-89 {
  align-items: flex-start;
  display: inline-flex;
  gap: 10.16px;
  height: 302px;
  left: 0;
  position: absolute;
  top: 3885px;
}

.vykhari-case-study .rectangle-33 {
  height: 301.58px;
  margin-left: -5134.06px;
  margin-top: -261.56px;
  object-fit: cover;
  position: relative;
  width: 218.17px;
}

.vykhari-case-study .rectangle-34 {
  height: 301.58px;
  margin-left: -4905.73px;
  margin-top: -261.56px;
  object-fit: cover;
  position: relative;
  width: 217.63px;
}

.vykhari-case-study .rectangle-35 {
  height: 301.58px;
  margin-left: -4677.94px;
  margin-top: -261.56px;
  object-fit: cover;
  position: relative;
  width: 218.17px;
}

.vykhari-case-study .MG-copy-7 {
  height: 295px;
  left: -5134px;
  object-fit: cover;
  position: absolute;
  top: 7202px;
  width: 344px;
}

.vykhari-case-study .MG-copy-8 {
  height: 295px;
  left: -4781px;
  object-fit: cover;
  position: absolute;
  top: 7202px;
  width: 321px;
}

.vykhari-case-study .frame-90 {
  align-items: flex-end;
  display: inline-flex;
  gap: 293.56px;
  justify-content: center;
  left: 6px;
  position: absolute;
  top: 4224px;
}

.vykhari-case-study .frame-91 {
  align-items: flex-start;
  display: inline-flex;
  gap: 10.16px;
  height: 302px;
  left: 6px;
  position: absolute;
  top: 4617px;
}

.vykhari-case-study .rectangle-36 {
  height: 301.58px;
  margin-left: -5140.47px;
  margin-top: -994.13px;
  object-fit: cover;
  position: relative;
  width: 218.17px;
}

.vykhari-case-study .rectangle-37 {
  height: 301.58px;
  margin-left: -4912.15px;
  margin-top: -994.13px;
  object-fit: cover;
  position: relative;
  width: 217.63px;
}

.vykhari-case-study .rectangle-38 {
  height: 301.58px;
  margin-left: -4684.35px;
  margin-top: -994.13px;
  position: relative;
  width: 218.17px;
}

.vykhari-case-study .MG-copy-9 {
  height: 295px;
  left: -5128px;
  object-fit: cover;
  position: absolute;
  top: 7935px;
  width: 446px;
}

.vykhari-case-study .MG-copy-10 {
  height: 295px;
  left: -4672px;
  object-fit: cover;
  position: absolute;
  top: 7935px;
  width: 219px;
}

.vykhari-case-study .element-14 {
  height: 1578px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 3156px;
  width: 1920px;
}

.vykhari-case-study .mask-group-wrapper {
  background-image: url(../../../static/img/rectangle-122.svg);
  background-size: 100% 100%;
  height: 540px;
  left: 0;
  position: absolute;
  top: 16484px;
  width: 1920px;
}

.vykhari-case-study .mask-group-2 {
  height: 540px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1920px;
}

.vykhari-case-study .frame-92 {
  align-items: flex-start;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 56px;
  left: 0;
  padding: 100px 120px;
  position: absolute;
  top: 15993px;
  width: 1919px;
}

.vykhari-case-study .element-delivered {
  align-self: stretch;
  color: #000000;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: -0.8px;
  line-height: 56px;
  margin-top: -1px;
  position: relative;
}

.vykhari-case-study .frame-93 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.vykhari-case-study .frame-94 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
}

.vykhari-case-study .frame-95 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  position: relative;
}

.vykhari-case-study .text-wrapper-127 {
  color: #000000;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 28px;
  font-weight: 500;
  letter-spacing: -0.56px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.vykhari-case-study .text-wrapper-128 {
  color: #979797;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vykhari-case-study .text-wrapper-129 {
  color: #0d99ff;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.48px;
  line-height: normal;
  position: relative;
  text-decoration: underline;
  width: fit-content;
}
