.div-wrapper {
  height: 22px;
  width: 114px;
}

.div-wrapper .group-4 {
  height: 22px;
  position: relative;
  width: 116px;
}

.div-wrapper .text-wrapper-4 {
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 22.5px;
  position: absolute;
  text-decoration: underline;
  top: 0;
  width: 96px;
}

.div-wrapper .ellipse-2 {
  background-color: #ffffff;
  border-radius: 9px;
  height: 18px;
  left: 96px;
  position: absolute;
  top: 3px;
  width: 18px;
}

.div-wrapper .img {
  height: 18px;
  left: 96px;
  position: absolute;
  top: 3px;
  width: 18px;
}

.div-wrapper .frame-162983 {
  color: #ffffff;
}

.div-wrapper .frame-162984 {
  color: #e9a97d;
}
