.component-27 {
  height: 1037px;
  position: relative;
  width: 1264px;
}

.component-27 .DSC-copy {
  height: 1037px;
  left: 523px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 741px;
}

.component-27 .DSC-copy-2 {
  height: 533px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 504px;
  width: 488px;
}
